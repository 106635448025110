import { Getter, Getters, Template } from "@devexpress/dx-react-core";
import {
    CustomSummary,
    DataTypeProvider,
    IntegratedSorting,
    IntegratedSummary,
    RowDetailState,
    SortingState,
    SummaryItem,
    SummaryState,
} from "@devexpress/dx-react-grid";
import { Grid, Table, TableFixedColumns, TableHeaderRow, TableRowDetail, TableSummaryRow } from "@devexpress/dx-react-grid-material-ui";
import { styled } from "@mui/material";
import {
    CurrencyDto,
    EffectCategoryAttributeDto,
    EffectCategoryDto,
    EffectField,
    GateTaskConfigDto,
    GenerationDto,
    IDateRange,
    MeasureDto,
    mergeCamelized,
} from "api-shared";
import { TFunction } from "i18next";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import useFieldData from "../../../../hooks/useFieldData";
import useTimezone from "../../../../hooks/useTimezone";
import { getFieldsForGate } from "../../../../lib/fields";
import { compareNullableDate } from "../../../../lib/utils";
import { Language, translationKeys } from "../../../../translations/main-translations";
import EffectCategoryDetails from "../EffectCategoryDetails";
import EffectCategoryTableActions from "./EffectCategoryTableActions";
import EffectFormatter from "./EffectFormatter";
import SummaryItemComponent from "./SummaryItem";
import TooltipFormatter from "./TooltipFormatter";

const actionsClass = "EffectCategoryTable-actions";

const TableRow = styled((props: Table.DataRowProps) => <Table.Row {...props} />)({
    [`&:hover .${actionsClass}`]: {
        // show the actions for the hovered row
        visibility: "visible",
    },
});

const TableTotalRow = styled((props: Table.RowProps) => <TableSummaryRow.TotalRow {...props} />)({
    [`&:hover .${actionsClass}`]: {
        // show the actions for the hovered row
        visibility: "visible",
    },
});

const HoverableActions = styled(EffectCategoryTableActions)({
    // hide action cells by default
    visibility: "hidden",
});

interface IEffectCategoryTableProps {
    effectCategories: EffectCategoryDto[];
    expandedRowIds: number[];
    disabled?: boolean;
    gateTaskConfig: GateTaskConfigDto;
    isFirstEffectGate: boolean;
    processName: string;
    measureFields: MeasureDto["fields"];
    effectCategoryFields: EffectCategoryAttributeDto[];
    onExpandedRowIdsChange: (newIds: number[]) => void;
    onEdit: (effectCategoryId: number) => void;
    onRemove: (effectCategoryId: number) => void;
    onHistory: (effectCategoryId: number) => void;
    onCopy: (effectCategoryId?: number) => void;
    previousGenerationName: string;
    translate: TFunction;
    lang: Language;
    invalidEffectCategories: number[];
    generations: GenerationDto[];
    gateTaskId: number;
    defaultCurrency: CurrencyDto;
}

interface RelativeEffect {
    value: number | null;
    relativeValue: number | null;
    currency: CurrencyDto;
}

interface CurrencyValue {
    value: number | null;
    currency: CurrencyDto;
}

enum Columns {
    EffectType = "effectType",
    Effect = "effect",
    Timerange = "timerange",
    Actions = "actions",
}
export interface EffectCategoryTableRow {
    initial: CurrencyValue;
    target: CurrencyValue;
    effectType: string;
    effect: RelativeEffect;
    [Columns.Timerange]: IDateRange;
    fields: Record<string, any>;
    id: number;
    isValid: boolean;
}

const DetailCell = styled((props: TableRowDetail.CellProps) => <TableRowDetail.Cell {...props} />)({
    "&.TableDetailCell-active": {
        padding: 0,
    },
});

const TableCell = styled(({ expanded, ...props }: Table.DataCellProps & { expanded?: boolean }) => <Table.Cell {...props} />)(
    ({ expanded }) => ({
        ...(expanded && { borderBottom: "none" }),
    }),
);

const TableToggleCell = styled((props: TableRowDetail.ToggleCellProps) => <TableRowDetail.ToggleCell {...props} />)(({ expanded }) => ({
    ...(expanded && { borderBottom: "none" }),
}));

const TableTotalCell = styled(({ expanded, ...props }: TableSummaryRow.CellProps & { expanded?: boolean }) => (
    <TableSummaryRow.TotalCell {...props} />
))(({ expanded, theme }) => ({
    "&.TableCell-footer": {
        borderTop: "none",
    },
    ...(expanded && { borderBottom: "none" }),
}));

const TableFixedCell = (props: TableFixedColumns.CellProps) => (
    <TableFixedColumns.Cell
        {...props}
        showLeftDivider={false}
        // use style instead of class to override styles set by a selector with high specificity (3 nested classes)
        style={{
            overflow: "visible", // make absolute positioned children visible
            padding: 0, // remove padding so fixed cell does not occupy any space
        }}
    />
);

const isSummaryDetailToggleCell = ({ tableRow, tableColumn }: any) =>
    // only apply/render this table on detail column in summary row
    tableColumn.type === TableRowDetail.COLUMN_TYPE && tableRow.type === TableSummaryRow.TOTAL_ROW_TYPE;

const getFirstColumnName = (effectCategoryFields: EffectCategoryAttributeDto[]) => {
    const fields = [...effectCategoryFields];
    fields.sort((a, b) => (a.order !== null && b.order !== null ? a.order - b.order : 0));
    return fields.length > 0 ? fields[0].title : "name";
};

const createSummaryCalculator =
    (
        rows: EffectCategoryTableRow[],
        effectCategoryFields: EffectCategoryAttributeDto[],
        currency: CurrencyDto,
        translate: TFunction,
        gateTaskConfig: GateTaskConfigDto,
        measureFields: Record<string, Record<string, any>>,
        hasIgnoredValues: boolean,
    ) =>
    ({ columnName, type }: SummaryItem) => {
        const {
            [mergeCamelized("total", gateTaskConfig.calculationIdentifier, EffectField.Effect)]: totalGateEffect,
            [mergeCamelized("total", gateTaskConfig.calculationIdentifier, EffectField.Initial)]: totalGateInitial,
            [mergeCamelized("total", gateTaskConfig.calculationIdentifier, "pl")]: totalGatePl,
        } = getFieldsForGate(measureFields, gateTaskConfig.id, true);

        let hasRelativeSummary: boolean, totalEffect: number, totalInitial: number;
        switch (columnName) {
            case Columns.Timerange:
                return {
                    start: totalGatePl?.value?.start != null ? moment(totalGatePl.value.start).toDate() : null,
                    end: totalGatePl?.value?.end != null ? moment(totalGatePl.value.end).toDate() : null,
                };
            case getFirstColumnName(effectCategoryFields):
                return translate(translationKeys.VDLANG_EFFECT_CATEGORY_GATE_TOTAL_EFFECT);
            case Columns.Actions:
                return {};
            case Columns.Effect:
                hasRelativeSummary = rows.every((row) => row.initial.value !== null && row.target !== null);
                totalEffect = Number(totalGateEffect?.value);
                totalInitial = Number(totalGateInitial?.value);
                return {
                    value: totalEffect,
                    relativeValue: hasRelativeSummary && totalInitial !== 0 ? totalEffect / totalInitial : null,
                    currency,
                    hasIgnoredValues,
                };
            default:
                return IntegratedSummary.defaultCalculator(type, rows, (row) => row[columnName]);
        }
    };

/**
 * Extract the values from an effect category depending on the gate
 *
 * @param {EffectCategoryDto} effectCategory
 * @param {number} gateTaskConfigId
 * @param {string} calculationIdentifier
 * @return {*}
 */
const getValuesForGate = (
    effectCategory: EffectCategoryDto,
    generations: GenerationDto[],
    currency: CurrencyDto,
): {
    [EffectField.Initial]: CurrencyValue;
    [EffectField.Target]: CurrencyValue;
    [EffectField.Effect]: RelativeEffect;
    [Columns.Timerange]: IDateRange;
} => {
    const generation = generations.find((generation) => generation.effectCategoryId === effectCategory.id);
    if (generation === undefined || generation.inputFields === undefined) {
        return {
            [EffectField.Initial]: { value: null, currency },
            [EffectField.Target]: { value: null, currency },
            [EffectField.Effect]: { value: null, relativeValue: null, currency },
            [Columns.Timerange]: { start: null, end: null },
        };
    }

    const initial = generation.inputFields[EffectField.Initial].value;
    const effect = generation.inputFields[EffectField.Effect].value;
    const target = generation.inputFields[EffectField.Target].value;
    const start = generation.inputFields.pl.value.start;
    const end = generation.inputFields.pl.value.end;
    const relativeEffect = initial != null && initial !== 0 && effect != null ? effect / initial : null;

    return {
        [EffectField.Initial]: { value: initial, currency },
        [EffectField.Target]: { value: target, currency },
        [EffectField.Effect]: { value: effect, relativeValue: relativeEffect, currency },
        [Columns.Timerange]: { start: start != null ? new Date(start) : null, end: end != null ? new Date(end) : null },
    };
};

const currencyColumns = [Columns.Effect];
const timerangeColumns = [Columns.Timerange];
const actionColumns = [Columns.Actions];
const standardSummaryColumns = [
    { columnName: Columns.Effect, type: "custom" },
    { columnName: Columns.Timerange, type: "custom" },
    { columnName: Columns.Actions, type: "custom" },
];

const tableColumnExtensions = [
    { columnName: Columns.EffectType, width: "150" },
    { columnName: Columns.Effect, align: "right" as const },
    { columnName: Columns.Timerange, width: "220" },
    // by default, the last column is invisible (1px), but will be shown on hover on the hovered row
    // IE11 does not support sticky columns, so show a column width fixed width
    // 100px roughly corresponds to three IconButtons
    { columnName: Columns.Actions, width: "1", align: "right" as const },
];

const sortingStateColumnExtensions = [{ columnName: Columns.Actions, sortingEnabled: false }];
const sortingColumnExtensions: IntegratedSorting.ColumnExtension[] = [
    {
        columnName: Columns.Effect,
        compare: (a: RelativeEffect, b: RelativeEffect) => (a?.value ?? 0) - (b?.value ?? 0),
    },
    {
        columnName: Columns.Timerange,
        compare: (a: IDateRange, b: IDateRange) => {
            const startDiff = compareNullableDate(a.start, b.start);
            return startDiff !== 0 ? startDiff : compareNullableDate(a.end, b.end);
        },
    },
];

const EffectCategoryTable = ({
    expandedRowIds,
    onExpandedRowIdsChange,
    effectCategories,
    gateTaskConfig,
    processName,
    translate,
    measureFields,
    disabled,
    isFirstEffectGate,
    onCopy,
    onEdit,
    onRemove,
    onHistory,
    previousGenerationName,
    effectCategoryFields,
    lang,
    invalidEffectCategories,
    generations,
    gateTaskId,
    defaultCurrency,
}: IEffectCategoryTableProps) => {
    const { formatDate } = useTimezone();
    const summaryColumns = useMemo(
        () => [...standardSummaryColumns, { columnName: getFirstColumnName(effectCategoryFields), type: "custom" }],
        [effectCategoryFields],
    );

    const columns = useMemo(() => {
        return [
            ...[...effectCategoryFields]
                .sort((a, b) => (a.order !== null && b.order !== null ? a.order - b.order : 0))
                .map((field) => ({ name: field.title, title: translate(field.title) })),
            { name: Columns.EffectType, title: translate(translationKeys.VDLANG_EFFECT_CATEGORY_GATE_HEADER_EFFECT_TYPE), lang },
            { name: Columns.Effect, title: translate(translationKeys.VDLANG_EFFECT_CATEGORY_GATE_HEADER_EFFECT), lang },
            { name: Columns.Timerange, title: translate(translationKeys.VDLANG_EFFECT_CATEGORY_GATE_HEADER_TIMERANGE) },
            {
                name: Columns.Actions,
                // Display empty title in header for actions column by using a zero-width whitespace here
                title: "​",
            },
        ].map((column, index) => ({ ...column, index }));
    }, [translate, lang, effectCategoryFields]);

    const textColumns = useMemo(() => {
        const specialColumns: string[] = [...currencyColumns, ...actionColumns, ...timerangeColumns];
        return columns.map(({ name }) => name).filter((name) => !specialColumns.includes(name));
    }, [columns]);

    const fieldData = useFieldData(effectCategoryFields, { fullTreePathValues: true });

    const [expandedSummary, setExpandedSummary] = useState(false);

    const rows: EffectCategoryTableRow[] = useMemo(
        () =>
            effectCategories.map((ec) => {
                const gateValues = getValuesForGate(ec, generations, ec.currency);
                const generation = generations.find(
                    (generation) => generation.effectCategoryId === ec.id && generation.gateTaskId === gateTaskId,
                );
                const generationFields = generation?.inputFields != null ? generation.inputFields : {};
                const { [EffectField.Effect]: gateEffect, ...fields } = generationFields;
                const effectCategoryValues: Record<string, string> = {};
                effectCategoryFields.forEach((attribute, index) => {
                    const value = ec.effectCategoryValues.find((value) => value.effectCategoryAttributeId === attribute.id);
                    const option = fieldData[index].find(({ id }) => id === value?.value);
                    effectCategoryValues[attribute.title] = option?.name ?? "";
                });

                const isValid = !invalidEffectCategories.includes(ec.id);

                return {
                    id: ec.id,
                    isValid,
                    fields,
                    effectType: translate(`effect_type_${ec.effectType}`),
                    ...effectCategoryValues,
                    ...gateValues,
                };
            }),
        [effectCategories, effectCategoryFields, fieldData, invalidEffectCategories, generations, gateTaskId, translate],
    );

    const summaryValues = useMemo(() => {
        const hasIgnoredValues = effectCategories.some((ec) => invalidEffectCategories.includes(ec.id));
        return summaryColumns.map(
            createSummaryCalculator(
                rows,
                effectCategoryFields,
                defaultCurrency,
                translate,
                gateTaskConfig,
                measureFields,
                hasIgnoredValues,
            ),
        );
    }, [
        effectCategoryFields,
        gateTaskConfig,
        measureFields,
        defaultCurrency,
        rows,
        summaryColumns,
        translate,
        effectCategories,
        invalidEffectCategories,
    ]);

    const showSummaryRow = effectCategories.length > 1;

    const updateExpandedRows = useCallback(
        (newIds: (string | number)[]) => onExpandedRowIdsChange(newIds.map((i) => +i)),
        [onExpandedRowIdsChange],
    );

    const computeFooterRows = useCallback(
        ({ tableFooterRows }: Getters) => {
            if (!expandedSummary) {
                return tableFooterRows;
            }

            const { [mergeCamelized("total", gateTaskConfig.calculationIdentifier, EffectField.Effect)]: totalGateEffect, ...fields } =
                getFieldsForGate(measureFields, gateTaskConfig.id, true);

            return tableFooterRows.concat({
                key: `${TableRowDetail.ROW_TYPE.toString()}_${String(tableFooterRows[0].rowId)}`,
                type: TableRowDetail.ROW_TYPE,
                row: { fields },
            });
        },
        [expandedSummary, measureFields, gateTaskConfig.id, gateTaskConfig.calculationIdentifier],
    );

    const RowDetail = useCallback(
        ({ row: { fields, effect } }: TableRowDetail.ContentProps) => (
            <EffectCategoryDetails
                fields={fields}
                calculationIdentifier={gateTaskConfig.calculationIdentifier}
                translate={translate}
                processName={processName}
                currency={effect?.currency ?? defaultCurrency}
            />
        ),
        [translate, processName, gateTaskConfig.calculationIdentifier, defaultCurrency],
    );

    const ExpandableTableCell = useCallback(
        (props: Table.DataCellProps) => <TableCell expanded={expandedRowIds.includes(Number(props.tableRow.rowId))} {...props} />,
        [expandedRowIds],
    );
    const ExpandableTotalCell = useCallback(
        (props: TableSummaryRow.CellProps) => <TableTotalCell expanded={expandedSummary} {...props} />,
        [expandedSummary],
    );

    const TimerangeFormatter = useCallback(
        ({ row, value, ...other }: DataTypeProvider.ValueFormatterProps) => {
            const { start, end } = value as IDateRange;
            const text =
                start != null && end != null ? `${formatDate(start, { noTimezone: true })} - ${formatDate(end, { noTimezone: true })}` : "";
            return <TooltipFormatter value={text} row={row} {...other} />;
        },
        [formatDate],
    );

    const ActionsFormatter = useCallback(
        ({ row }: DataTypeProvider.ValueFormatterProps) => {
            const id = row?.id;
            return (
                <HoverableActions
                    isSummary={row === undefined}
                    className={actionsClass}
                    disabled={disabled}
                    onEdit={id !== undefined ? () => onEdit(id) : undefined}
                    onCopy={() => onCopy(id)}
                    onRemove={id !== undefined ? () => onRemove(id) : undefined}
                    onHistory={id !== undefined ? () => onHistory(id) : undefined}
                    isFirstCalculationGate={isFirstEffectGate}
                    translate={translate}
                    previousGenerationName={previousGenerationName}
                    effectType={row?.effectType}
                />
            );
        },
        [disabled, onEdit, onCopy, onRemove, onHistory, translate, isFirstEffectGate, previousGenerationName],
    );

    return (
        <Grid rows={rows} columns={columns} getRowId={(r) => r.id}>
            <DataTypeProvider formatterComponent={EffectFormatter} for={currencyColumns} />
            <DataTypeProvider formatterComponent={TimerangeFormatter} for={timerangeColumns} />
            <DataTypeProvider formatterComponent={ActionsFormatter} for={actionColumns} />
            <DataTypeProvider formatterComponent={TooltipFormatter} for={textColumns} />
            {showSummaryRow && <SummaryState totalItems={summaryColumns} />}
            <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={updateExpandedRows} />
            <SortingState columnExtensions={sortingStateColumnExtensions} />
            <IntegratedSorting columnExtensions={sortingColumnExtensions} />
            {showSummaryRow && <CustomSummary totalValues={summaryValues} />}
            <Table columnExtensions={tableColumnExtensions} cellComponent={ExpandableTableCell} rowComponent={TableRow} />
            <TableHeaderRow showSortingControls messages={{ sortingHint: "" }} />
            <TableRowDetail contentComponent={RowDetail} cellComponent={DetailCell} toggleCellComponent={TableToggleCell} />
            {showSummaryRow && (
                <TableSummaryRow
                    itemComponent={SummaryItemComponent}
                    totalCellComponent={ExpandableTotalCell}
                    totalRowComponent={TableTotalRow}
                />
            )}
            {showSummaryRow && <Getter name="tableFooterRows" computed={computeFooterRows} />}
            {showSummaryRow && (
                <Template name="tableCell" predicate={isSummaryDetailToggleCell}>
                    {({ tableRow, tableColumn, row }: any) => (
                        <TableToggleCell
                            tableRow={tableRow}
                            tableColumn={tableColumn}
                            row={row}
                            expanded={expandedSummary}
                            onToggle={() => setExpandedSummary(!expandedSummary)}
                        />
                    )}
                </Template>
            )}
            <TableFixedColumns rightColumns={actionColumns} cellComponent={TableFixedCell} />
        </Grid>
    );
};

export default EffectCategoryTable;
