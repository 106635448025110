import { EffectCategoryAttributeDto } from "api-shared";
import { cloneDeep } from "lodash";
import { FilledField, removeDependsFromField } from "../../../../lib/fields";

// Allow duplicate effect category values  => https://valued.atlassian.net/browse/DEV-4197
//
//
// /**
//  * Get list of values that should be filtered in EffectCategoryValue selection.
//  *
//  * @param {number[][]} effectCategoryValuesInUse
//  * @param {{ id: number, measureAttributeId: number }[][]} allEffectCategoryValues
//  * @param {EffectCategoryAttributeDto[]} effectCategoryFields
//  * @param {Record<string, unknown>} changes
//  * @param {number} index
//  * @return {*}  {number[]}
//  */
// function getHiddenValuesForField(
//     effectCategoryValuesInUse: number[][],
//     allEffectCategoryValues: { id: number; measureAttributeId: number }[][],
//     effectCategoryFields: EffectCategoryAttributeDto[],
//     changes: Record<string, unknown>,
//     index: number,
// ): number[] {
//     const allEffectCategoryValueIds = allEffectCategoryValues.map((a) => a.map(({ id }) => id));
//     const selectedValues = effectCategoryFields.map((field) => (changes[field.title] as number) ?? null);

//     const allCartesians = getCartesiansForSelectedValues(allEffectCategoryValueIds, selectedValues, index);
//     if (allCartesians.length === 0) {
//         return [];
//     }

//     // remove combinations that are already used by existing EffectCategories
//     const filteredCartesians = removeExistingCombinations(allCartesians, effectCategoryValuesInUse);

//     // At this point we know which combinations are still available and we need to transpose the array.
//     // [[1,a], [1,b], [2,a], [2,b]] => [[ 1, 2], [a,b]]
//     const stillAvailableValues = transpose(filteredCartesians).map((ids) => new Set(ids)); // remove duplicates

//     // make sure current selected value is always available
//     selectedValues.forEach((value, index) => {
//         if (stillAvailableValues[index] === undefined) {
//             stillAvailableValues[index] = new Set();
//         }
//         value !== null && stillAvailableValues[index].add(value);
//     });

//     // If a value is in stillAvailableValues it shouldn't be hidden in the selection
//     // Keep order of input values here
//     return allEffectCategoryValueIds.map((values, index) => values.filter((value) => !stillAvailableValues[index].has(value)))[index];
// }

// function removeExistingCombinations(allCartesians: number[][], effectCategoryValuesInUse: number[][]): number[][] {
//     const stringifiedCartesians = JSON.stringify(allCartesians);
//     const cleanedCartesians = effectCategoryValuesInUse
//         .map((values) => JSON.stringify(values))
//         // regex is difficult here, as it would need additional escaping of [], characters
//         .reduce(
//             // prefer removing value with pre/suffixed comma
//             // fallback to removing without any comma, which may happen when the removed element is the last element
//             (value, toRemove) => value.replace(`${toRemove},`, "").replace(`,${toRemove}`, "").replace(`${toRemove}`, ""),
//             stringifiedCartesians,
//         );
//     return JSON.parse(cleanedCartesians);
// }

// /**
//  * Generate all possible combiantions of EffectCategoryValues for a existing EffectCategory.
//  *
//  * @param {number[][]} allEffectCategoryValueIds
//  * @param {unknown[][]} selectedValues
//  * @return {*}  {number[][]}
//  */
// function getCartesiansForSelectedValues(
//     allEffectCategoryValueIds: number[][],
//     selectedValues: (number | null)[],
//     ignoreIndex: number,
// ): number[][] {
//     const availableValues = selectedValues.map((value, index) =>
//         value === null || index === ignoreIndex ? allEffectCategoryValueIds[index] : [value],
//     );
//     return generateCartesian(...availableValues);
// }

// /**
//  * Generate cartesians products.
//  * Example:
//  * [[ 1, 2], [a,b]] => [[1,a], [1,b], [2,a], [2,b]]
//  *
//  * @template T
//  * @param {...T[][]} ids
//  * @returns {T[][]}
//  */
// function generateCartesian<T>(...ids: T[][]): T[][] {
//     return ids.reduce(
//         (generatedCartesians: T[][], nextListOfValues: T[]) =>
//             generatedCartesians.flatMap((cartesian: T[]) => nextListOfValues.map((nextValue: T) => [...cartesian, nextValue])),
//         [[]] as T[][],
//     );
// }

interface AdditionalFieldData {
    valuesInUse: number[];
    allValues: number[];
}

interface IUseCategoryFieldsProps {
    effectCategoryFields: EffectCategoryAttributeDto[];
    showCategoryFields: boolean;
    allEffectCategoryValues: any[][];
    effectCategoryValuesInUse: number[][];
    changes: Record<string, unknown>;
    updatedCategory: Record<string, unknown>;
    additionalFields: Record<string, AdditionalFieldData>;
}

function useCategoryFields({
    effectCategoryFields,
    showCategoryFields,
    allEffectCategoryValues,
    effectCategoryValuesInUse,
    changes,
    updatedCategory,
    additionalFields,
}: IUseCategoryFieldsProps): { categoryFields: FilledField[]; additionalFields: Record<string, { filteredValues: number[] }> } {
    if (!showCategoryFields) {
        // skip costly computations in case those fields will not be used
        return { categoryFields: [], additionalFields: {} };
    }

    // Deep copy required for multi-dimensional arrays because spread operator is only doing shallow copies
    const valuesInUse = cloneDeep(effectCategoryValuesInUse);
    const allValues = cloneDeep(allEffectCategoryValues);

    // add additional non-attribute fields to calculation
    let fieldsWithAdditionalFields = effectCategoryFields;
    const additionalFieldsKeys = Object.keys(additionalFields);

    additionalFieldsKeys.forEach((key) => {
        const additionalField = additionalFields[key];
        valuesInUse.forEach((ecValues, index) => ecValues.push(additionalField.valuesInUse[index]));
        allValues.push(additionalField.allValues.map((id) => ({ id })));
        fieldsWithAdditionalFields = [...fieldsWithAdditionalFields, { title: key } as any];
    });

    // compute hidden values for additional field
    const additionalFieldsResult: Record<string, { filteredValues: number[] }> = {};
    additionalFieldsKeys.forEach((key, index) => {
        additionalFieldsResult[key] = {
            filteredValues: [],
        };
    });

    return {
        categoryFields: effectCategoryFields
            .map((field, index) => ({
                ...field,
                filteredValues: [],
                value: updatedCategory[field.title],
            }))
            .map(removeDependsFromField),

        additionalFields: additionalFieldsResult,
    };
}

export default useCategoryFields;
