import { Grid } from "@mui/material";
import { GateTaskDto } from "api-shared";
import { TFunction } from "i18next";
import React, { useState } from "react";
import ConfirmDialog from "../../../components/dialogues/ConfirmDialog";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import { useCurrentClient } from "../../../domain/client";
import { useDefaultCurrency } from "../../../domain/currencies";
import { useEffectCategories } from "../../../domain/effect-category";
import { useGenerations, useUpdateGeneration } from "../../../domain/generation";
import { useEffectCategoryAttributesForMeasure } from "../../../domain/measure-config";
import useFieldData from "../../../hooks/useFieldData";
import { useLanguage } from "../../../hooks/useLanguage";
import {
    getEffectCategoryCurrenciesInUse,
    getEffectCategoryEffectTypesInUse,
    getEffectCategoryValuesInUse,
} from "../../../lib/effect-category";
import { translationKeys } from "../../../translations/main-translations";
import { useMeasureContext } from "../../MeasureContext";
import AddEffectCategoryButtons from "../AddEffectCategoryButtons";
import CalculatorImage from "../CalculatorImage";
import EffectCategoryDialog from "./EffectCategoryDialog";
import CalculationHistoryDialog from "./effect-category/CalculationHistoryDialog";
import EffectCategoryTable from "./effect-category/EffectCategoryTable";
import { useCalculation } from "./effect-category/useCalculation";

interface ICalculationLevelProps {
    gateTask: GateTaskDto;
    translate: TFunction;
    disabled: boolean;
    className?: string;
}

const CalculationLevel = ({ gateTask, translate, disabled: disabledByProp, className }: ICalculationLevelProps) => {
    const [expanded, setExpanded] = useState<number[]>([]);
    const [categoryToShowHistory, setCategoryToShowHistory] = useState<number | null>(null);

    const measure = useMeasureContext();
    const allGenerations = useGenerations(measure.id);
    const effectCategories = useEffectCategories(measure.id);
    const updateGeneration = useUpdateGeneration().mutate;

    // null => no category selected
    // undefined => all categories shall be copied
    const [categoryToCopy, setCategoryToCopy] = useState<number | null | undefined>(null);

    const lang = useLanguage();
    const client = useCurrentClient();
    const defaultCurrency = useDefaultCurrency();
    const effectCategoryFields = useEffectCategoryAttributesForMeasure(measure);
    const allEffectCategoryValues = useFieldData(effectCategoryFields);

    const {
        categoryToEdit,
        setCategoryToEdit,
        categoryToDelete,
        setCategoryToDelete,
        addCategory,
        removeEffectCategory,
        defaultEffectType,
        copyFields,
        saveCategory,
        generations,
    } = useCalculation({
        measureId: measure.id,
        gateTaskId: gateTask.id,
        allGenerations: allGenerations.data ?? [],
        updateGeneration,
        onAddEffectCategorySuccess: (id) => {
            effectCategories.refetch();
            setExpanded([id]);
        },
    });

    const gateTaskConfig = measure.measureConfig.gateTaskConfigs.find((gtc) => gtc.id === gateTask.gateTaskConfigId);

    const effectGateTaskConfigs = measure.measureConfig.gateTaskConfigs
        .filter(({ calculationIdentifier }) => calculationIdentifier !== null)
        .sort((a, b) => a.order - b.order);
    const isFirstEffectGate = effectGateTaskConfigs.length > 0 && effectGateTaskConfigs[0].id === gateTask.gateTaskConfigId;

    const generation = effectGateTaskConfigs.findIndex(({ id }) => id === gateTask.gateTaskConfigId);
    const previousGenerationName = generation > 0 ? translate(effectGateTaskConfigs[generation - 1].name) : "";
    const invalidEffectCategories = measure.invalidProperties.calculations[gateTask.id];

    if (gateTaskConfig == null) {
        return null;
    }

    const copyConfirmed = () => {
        if (categoryToCopy !== null) {
            copyFields(categoryToCopy);
            setCategoryToCopy(null);
        }
    };

    if (!effectCategories.isSuccess) {
        return null;
    }

    const hasEffectCategories = effectCategories.data.length > 0;

    const disabled = disabledByProp || gateTask.locked;

    return (
        <div className={className}>
            <EffectCategoryDialog
                key={`${defaultEffectType}${Number(categoryToEdit)}`}
                open={Boolean(categoryToEdit)}
                onClose={() => setCategoryToEdit(null)}
                translate={translate}
                processName={measure.measureConfig.name}
                client={client}
                lang={lang}
                effectCategory={effectCategories.data.find(({ id }) => id === categoryToEdit)}
                onSave={saveCategory}
                gateTaskConfig={gateTaskConfig}
                disabled={disabled}
                effectCategoryFields={effectCategoryFields}
                allEffectCategoryValues={allEffectCategoryValues}
                effectCategoryValuesInUse={getEffectCategoryValuesInUse(effectCategories.data, categoryToEdit)}
                showCategoryFields={isFirstEffectGate}
                showCalculationFields
                defaultEffectType={defaultEffectType}
                effectTypes={getEffectCategoryEffectTypesInUse(effectCategories.data, categoryToEdit)}
                currencyIdsInUse={getEffectCategoryCurrenciesInUse(effectCategories.data, categoryToEdit)}
                generations={generations}
                withBadge={true}
                measure={measure}
            />
            <DeleteDialog
                item={`effect_type_${effectCategories.data.find(({ id }) => categoryToDelete === id)?.effectType ?? defaultEffectType}`}
                translate={translate}
                open={categoryToDelete != null}
                onClose={() => setCategoryToDelete(null)}
                onDelete={removeEffectCategory}
            />
            <ConfirmDialog
                open={categoryToCopy !== null}
                onClose={() => setCategoryToCopy(null)}
                item="category"
                title={translate("Copy")}
                primary={translationKeys.VDLANG_CONFIRM}
                translate={translate}
                onConfirm={copyConfirmed}
                primaryDanger
            >
                {translate(translationKeys.VDLANG_CALCULATION_TABLE_COPY_DATA_WARNING)}
            </ConfirmDialog>
            {categoryToShowHistory !== null ? (
                <CalculationHistoryDialog
                    open={Boolean(categoryToShowHistory)}
                    categoryId={categoryToShowHistory}
                    translate={translate}
                    onClose={() => setCategoryToShowHistory(null)}
                    gateTaskConfigs={measure.measureConfig.gateTaskConfigs}
                    generations={allGenerations.data ?? []}
                    gateTasks={measure.gateTasks}
                />
            ) : null}
            {hasEffectCategories ? (
                <EffectCategoryTable
                    effectCategories={effectCategories.data}
                    expandedRowIds={expanded}
                    onExpandedRowIdsChange={setExpanded}
                    onEdit={setCategoryToEdit}
                    onCopy={setCategoryToCopy}
                    onRemove={setCategoryToDelete}
                    onHistory={(id) => setCategoryToShowHistory(id)}
                    gateTaskConfig={gateTaskConfig}
                    translate={translate}
                    disabled={disabled}
                    processName={measure.measureConfig.name}
                    measureFields={measure.fields}
                    isFirstEffectGate={isFirstEffectGate}
                    previousGenerationName={previousGenerationName}
                    effectCategoryFields={effectCategoryFields}
                    lang={lang}
                    invalidEffectCategories={invalidEffectCategories}
                    generations={generations}
                    gateTaskId={gateTask.id}
                    defaultCurrency={defaultCurrency}
                />
            ) : (
                <Grid container justifyContent="center" p={3}>
                    <Grid item>
                        <CalculatorImage />
                    </Grid>
                </Grid>
            )}
            {isFirstEffectGate && !disabled && (
                <Grid container justifyContent={hasEffectCategories ? "flex-start" : "center"} p={3}>
                    <Grid item>
                        <AddEffectCategoryButtons
                            onAdd={addCategory}
                            disabled={disabled}
                            translate={translate}
                            allValues={allEffectCategoryValues}
                            effectCategories={effectCategories.data}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default React.memo(CalculationLevel);
