import LockOutlined from "@mui/icons-material/LockOutlined";
import { Stack, StepIcon, Tooltip, Typography, stepIconClasses, styled } from "@mui/material";
import { FeatureFlags, GateStatus, GateTaskConfigDto, GateTaskDto, GateTaskType, UserDto, formatDateForAPI } from "api-shared";
import { TFunction } from "i18next";
import moment from "moment";
import momentTimezone from "moment-timezone";
import InfoIcon from "../../components/icons/InfoIcon";
import DatePicker from "../../components/input/date/DatePicker";
import SingleUser from "../../components/user/SingleUser";
import { useSubTasksByMeasure } from "../../domain/subtasks";
import { formatDateTimeForApi } from "../../lib/formatters";
import { translationKeys } from "../../translations/main-translations";
import { GateTaskUpdateDto } from "./Gate";
import ActivityLevelChart from "./levels/ActivityLevelChart";
import GenerationChip from "./levels/GenerationChip";
import { useClientHasFeature } from "../../domain/client";

const Root = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(0.5, 3),
    margin: 0,
    width: "100%",
    minHeight: theme.spacing(7),
}));

const Grow = styled(Stack)({ flexGrow: 1 });

const GateStatusIcon = styled(StepIcon)(({ theme }) => ({
    [`&.${stepIconClasses.completed}`]: {
        color: theme.palette.natureGreen.main,
    },
}));

const GateHintIcon = styled(InfoIcon)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(20),
    color: theme.palette.action.active,
}));

const GateLockedIcon = styled(LockOutlined)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(20),
}));

interface IGateHeaderProps {
    gateTask: GateTaskDto;
    gateTaskConfig: GateTaskConfigDto;
    updateGateTask: (changes: Partial<GateTaskUpdateDto>) => void;
    gateIndex: number;
    isActive: boolean;
    translate: TFunction;
    name: string;
    disabled?: boolean;
    editors: UserDto[];
    updateAssignedTo: (userId: number | null) => void;
    assignedTo: UserDto | null;
    hintText: string | undefined;
}

const safeMoment = (input: string | null) => (input == null ? null : momentTimezone.utc(input));

const GateHeader = ({
    gateTask,
    gateTaskConfig,
    updateGateTask,
    gateIndex,
    isActive,
    translate,
    name,
    disabled,
    editors,
    updateAssignedTo,
    assignedTo,
    hintText,
}: IGateHeaderProps) => {
    const isCalculation = gateTaskConfig.type === GateTaskType.Calculation;
    const allActivities = useSubTasksByMeasure(gateTask.measureId).data ?? [];
    const activitiesForGate = allActivities.filter(({ gateTaskId }) => gateTaskId === gateTask.id);

    const hasMandatoryLevelDates = useClientHasFeature(FeatureFlags.FEATURE_MANDATORY_LEVEL_DATES);

    const isDisabled = disabled === true || (hasMandatoryLevelDates && gateTask.status !== GateStatus.STATUS_OPEN);

    return (
        <Root direction="row" spacing={2} alignItems="center" overflow="auto">
            <GateStatusIcon completed={gateTask.status === GateStatus.STATUS_COMPLETED} icon={gateIndex + 1} active={isActive} />

            <Grow direction="row" alignItems="center" spacing={1}>
                <Typography variant="h6">{translate(name)}</Typography>
                {gateTaskConfig.hintKey !== null && <GateHintIcon title={translate(gateTaskConfig.hintKey)} />}
                {isCalculation && gateTaskConfig.calculationIdentifier !== null && (
                    <GenerationChip
                        label={translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${gateTaskConfig.calculationIdentifier}`)}
                    />
                )}
                {gateTask.locked ? (
                    <Tooltip title={translate(`${translationKeys.VDLANG_CALCULATION_TABLE_TOOLTIP}.calculation_locked`)}>
                        <GateLockedIcon />
                    </Tooltip>
                ) : null}
            </Grow>
            <Stack direction="row">
                <DatePicker
                    disabled={isDisabled}
                    date={safeMoment(gateTask.startDate)}
                    onDateChange={(date) =>
                        updateGateTask({
                            startDate: date != null ? formatDateForAPI(date) : null,
                        })
                    }
                    onReminderChange={(startRemindAt) =>
                        updateGateTask({ startRemindAt: startRemindAt ? formatDateTimeForApi(startRemindAt) : null })
                    }
                    remindAt={gateTask.assignedToId ? gateTask.startRemindAt : undefined}
                    maxDate={gateTask.duedate != null ? moment.utc(gateTask.duedate) : undefined}
                    placeholder={`${translate(translationKeys.VDLANG_GATE_START_DATE_PLACEHOLDER)}${hasMandatoryLevelDates ? "*" : ""}`}
                />
                <DatePicker
                    disabled={isDisabled}
                    date={safeMoment(gateTask.duedate)}
                    onDateChange={(date) =>
                        updateGateTask({
                            duedate: date != null ? formatDateForAPI(date) : null,
                        })
                    }
                    onReminderChange={(remindAt) => updateGateTask({ remindAt: remindAt ? formatDateTimeForApi(remindAt) : null })}
                    remindAt={gateTask.assignedToId ? gateTask.remindAt : undefined}
                    minDate={gateTask.startDate != null ? moment.utc(gateTask.startDate) : undefined}
                    placeholder={`${translate(translationKeys.VDLANG_GATE_DUEDATE_DATE_PLACEHOLDER)}${hasMandatoryLevelDates ? "*" : ""}`}
                />
            </Stack>
            <SingleUser
                variant="avatar"
                avatarSize={32}
                closeOnSelect
                users={editors}
                disabled={disabled}
                update={updateAssignedTo}
                user={assignedTo}
                translate={translate}
                hintText={hintText}
            />
            <ActivityLevelChart disabled={disabled} gateTask={gateTask} activities={activitiesForGate} />
        </Root>
    );
};

export default GateHeader;
