import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GenerationListDto } from "api-shared";
import { apiGet, apiPatch } from "../lib/api";
import { MeasureDetailQueryKeys } from "./measure/detail";
import { ProcessPulseQueryKeys } from "./process-pulse";
import { ReportingQueryKeys } from "./reporting";

const GENERATIONS_PATH = "generations";

export const generationKeys = {
    forMeasure: (measureId: number) => [`${GENERATIONS_PATH}?measureId=${measureId}`],
};

export interface IUpdateGenerationInput {
    measureId: number;
    generationId: number;
    calculationFields: Record<string, unknown>;
}

export const useGenerations = (measureId: number) => {
    return useQuery({
        queryKey: generationKeys.forMeasure(measureId),
        queryFn: ({ signal }) => apiGet<GenerationListDto>(`${GENERATIONS_PATH}?measureId=${measureId}`, { signal }),
    });
};

export const useUpdateGeneration = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ generationId, calculationFields }: IUpdateGenerationInput) =>
            apiPatch(`${GENERATIONS_PATH}/${generationId}`, { ...calculationFields }),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(generationKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
        },
    });
};
