import { Box, Stack, styled } from "@mui/material";
import { CurrencyDto, mergeCamelized, removePrefix } from "api-shared";
import { TFunction } from "i18next";
import { camelCase, sortBy } from "lodash";
import { translationKeys } from "../../../translations/main-translations";
import EffectCategoryEffect from "./EffectCategoryEffect";

const Root = styled("div")(({ theme }) => ({
    maxWidth: "100%",
    overflow: "hidden", // let scrollContainer also scroll in IE
}));

const HorizontalScrollContainer = styled("div", { shouldForwardProp: (name) => name !== "noData" && name !== "isEmpty" })<{
    isEmpty: boolean;
}>(({ theme, isEmpty }) => ({
    padding: theme.spacing(2, 2, 2.5),
    overflowX: "auto",
    ...(isEmpty && {
        padding: theme.spacing(3),
        color: theme.palette.text.disabled,
    }),
}));

interface EffectTableItem {
    year: string;
    effect: number;
}

interface EffectItem {
    title: string;
    value: any;
    isNeutral: boolean;
}

interface IEffectCategoryDetailsProps {
    translate: TFunction;
    fields: Record<string, any>;
    processName: string;
    calculationIdentifier: string | null;
    currency: CurrencyDto;
}
const EffectCategoryDetails = ({ translate, fields, processName, calculationIdentifier, currency }: IEffectCategoryDetailsProps) => {
    const effectItems = Object.entries(fields)
        .filter(([, { type }]) => type === "effect_table" || type.startsWith("currency"))
        .filter(([, { value }]) => value !== null)
        .sort(([, aValue], [, bValue]) => aValue.order - bValue.order)
        .reduce((acc, [, { title: fieldTitle, type, value }]) => {
            if (type === "effect_table") {
                return value != null
                    ? sortBy(
                          [
                              ...acc,
                              ...value.map(({ year, effect }: EffectTableItem) => ({
                                  title: `${translate("FY")} ${year}`,
                                  value: effect,
                              })),
                          ],
                          (x) => (x.isNeutral ? 0 : x.title),
                      )
                    : acc;
            }
            const unprefixedFieldTitle = removePrefix(fieldTitle, calculationIdentifier);
            const titleTranslations = [
                `${unprefixedFieldTitle}_${processName}`,
                mergeCamelized(unprefixedFieldTitle, processName),
                unprefixedFieldTitle,
                camelCase(unprefixedFieldTitle),
            ];
            return [...acc, { title: translate(titleTranslations), value, isNeutral: true }];
        }, [] as EffectItem[]);
    const hasData = effectItems.length > 0;
    return (
        <Root>
            <HorizontalScrollContainer isEmpty={!hasData}>
                <Stack direction="row">
                    {hasData ? (
                        effectItems.map((item) => (
                            <Box flexGrow={1} flexShrink={0} px={2} key={item.title}>
                                <EffectCategoryEffect {...item} currency={currency} />
                            </Box>
                        ))
                    ) : (
                        <span>{translate(translationKeys.VDLANG_NO_CALCULATION_DATA)}</span>
                    )}
                </Stack>
            </HorizontalScrollContainer>
        </Root>
    );
};

export default EffectCategoryDetails;
