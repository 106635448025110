import { EffectCategoryDto } from "api-shared";

export function getEffectCategoryValuesInUse(effectCategories: EffectCategoryDto[], ignoreCategoryId: number | null): number[][] {
    return effectCategories
        .filter(({ id }) => id !== ignoreCategoryId)
        .map((ec) =>
            ec.effectCategoryValues.map((ecv) => {
                return ecv.value;
            }),
        );
}

export function getEffectCategoryCurrenciesInUse(effectCategories: EffectCategoryDto[], ignoreCategoryId: number | null): number[] {
    return effectCategories.filter((ec) => ec.id !== ignoreCategoryId).map(({ currencyId }) => currencyId);
}

export function getEffectCategoryEffectTypesInUse(effectCategories: EffectCategoryDto[], ignoreCategoryId: number | null): number[] {
    return effectCategories.filter((ec) => ec.id !== ignoreCategoryId).map(({ effectType }) => effectType);
}
