import {
    CalculationType,
    ClientDto,
    Currency,
    EffectCategoryAttributeDto,
    EffectCategoryDto,
    EffectField,
    EffectType,
    FirstDate,
    GateTaskConfigDto,
    GenerationDto,
    GenerationInputField,
    LastDate,
    MeasureDto,
    isDateBetween,
    mergeCamelized,
} from "api-shared";
import { TFunction } from "i18next";
import moment from "moment";
import { useState } from "react";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { useCurrencies } from "../../../domain/currencies";
import { useCurrentUser } from "../../../domain/users";
import { compareObjects } from "../../../lib/utils";
import { Language, translationKeys } from "../../../translations/main-translations";
import useCurrencyContext, { CurrencyContextProvider } from "../../CurrencyContext";
import EffectCategoryForm from "./EffectCategoryForm";
import GenerationChip from "./GenerationChip";
import useCategoryFields from "./effect-category/useCategoryFields";
import useEffectFields from "./effect-category/useEffectFields";

export function injectValueToField(input: any, changes: Record<string, unknown>, field: GenerationInputField) {
    const title = field.title;
    let fieldInput = input[title];

    if (field.type === "date_range" && fieldInput == null) {
        // in case date_range field has not been edited yet, take the values from the effect category
        // e.g. for field *Pl take the start and end date from EffectCategory
        // when it is edited, there is a *Pl value of type { start: string, end: string } in the state
        const prefix = title.slice(0, -2); // cut of "Pl" at the end
        fieldInput = {
            start: input[mergeCamelized(prefix, EffectField.StartDate)],
            end: input[mergeCamelized(prefix, EffectField.EndDate)],
        };
    }

    if (field.type === "currency,19,4") {
        const formula = input[mergeCamelized(title, "formula")];
        const currency = Currency.fromValue(changes[title] ?? formula ?? fieldInput);
        return {
            ...field,
            value: currency?.value ?? null,
            formula: currency?.formula ?? null,
        };
    }

    return {
        ...field,
        value: fieldInput,
    };
}

/**
 * Maps multiple Field values to EffectCategoryUpdate. See mapFieldValue
 *
 * @param {*} changes
 * @returns
 */
function mapFieldValues(changes: Record<string, unknown>) {
    return Object.entries(changes).reduce(
        (acc, [key, value]) => {
            const newValues = { [key]: value };
            return { ...acc, ...newValues };
        },
        {} as Record<string, any>,
    );
}

function getEffectGenerationForGate(): GenerationInputField[] {
    return [
        { title: EffectField.HasInitial, type: "boolean", order: 0, mandatory: false, value: null, formula: null },
        { title: EffectField.Initial, type: "currency,19,4", order: 1, mandatory: true, value: null, formula: null },
        { title: EffectField.Target, type: "currency,19,4", order: 2, mandatory: true, value: null, formula: null },
        { title: EffectField.Effect, type: "currency,19,4", order: 3, mandatory: true, value: null, formula: null },
        { title: EffectField.PriceHike, type: "currency,19,4", order: 4, mandatory: false, value: null, formula: null },
        { title: "pl", type: "date_range", order: 5, mandatory: true, value: null, formula: null },
    ];
}

/**
 * Get the fillable fields of an EffectCategory in a gate.
 * If no EffectCategory is provided, the default fields for the given gate will be returned.
 *
 * @param {(EffectCategoryDto | undefined)} effectCategory
 * @param {GateType} gateTaskConfig
 * @returns
 */
function getUncalculatedFields(effectCategory: EffectCategoryDto | undefined, generations: GenerationDto[]): GenerationInputField[] {
    const generation = generations.find((generation) => effectCategory?.id === generation.effectCategoryId);
    if (generation?.inputFields != null) {
        return Object.values(generation.inputFields).filter((field) => !field.calculated);
    }
    return getEffectGenerationForGate();
}

function getFlattendEffectCategoryValues(
    effectCategory: EffectCategoryDto,
    effectCategoryFields: EffectCategoryAttributeDto[],
): Record<string, unknown> {
    const flattenedDefaultValues: Record<string, unknown> = {
        id: effectCategory.id,
        effectType: effectCategory.effectType,
    };
    effectCategoryFields.forEach((field) => {
        const value = effectCategory.effectCategoryValues?.find((ecvalue) => ecvalue.effectCategoryAttributeId === field.id);
        if (value !== undefined) {
            flattenedDefaultValues[field.title] = value.value;
        }
    });
    return flattenedDefaultValues;
}

/**
 * Flatten the EffectCategoryValues.
 *
 * @param {(EffectCategoryDto | undefined)} effectCategory
 * @param {EffectCategoryAttributeDto[]} effectCategoryFields
 * @param {EffectType} [defaultEffectType]
 * @returns {Record<string, unknown>}
 */
function getInitialChangesState(
    effectCategory: EffectCategoryDto | undefined,
    effectCategoryFields: EffectCategoryAttributeDto[],
    defaultEffectType?: EffectType,
): Record<string, unknown> {
    if (effectCategory == null) {
        return {
            effectType: defaultEffectType,
            hasInitial: defaultEffectType === EffectType.Savings,
        };
    }

    return getFlattendEffectCategoryValues(effectCategory, effectCategoryFields);
}
interface IEffectCategoryDialogProps {
    open: boolean;
    onClose: () => void;
    translate: TFunction;
    processName: string;
    client: ClientDto;
    lang: Language;
    effectCategory?: EffectCategoryDto;
    onSave: (effectCategory: Record<string, unknown>) => void;
    gateTaskConfig: GateTaskConfigDto;
    disabled?: boolean;
    effectCategoryFields: EffectCategoryAttributeDto[];
    effectCategoryValuesInUse: number[][];
    allEffectCategoryValues: any[][];
    showCategoryFields: boolean;
    showCalculationFields: boolean;
    defaultEffectType: EffectType;
    effectTypes: EffectType[];
    generations: GenerationDto[];
    withBadge: boolean;
    measure: MeasureDto;
    currencyIdsInUse: number[];
}

const EffectCategoryDialog = ({
    open,
    onClose,
    onSave,
    translate,
    processName,
    client,
    lang,
    effectCategory,
    gateTaskConfig,
    disabled = false,
    effectCategoryFields,
    effectCategoryValuesInUse,
    allEffectCategoryValues,
    showCategoryFields,
    showCalculationFields,
    defaultEffectType,
    effectTypes,
    generations,
    withBadge = true,
    measure,
    currencyIdsInUse,
}: IEffectCategoryDialogProps) => {
    // TODO: make effectCategory.fields not undefined
    const calculationFieldValues = getUncalculatedFields(effectCategory, generations).reduce(
        (values, field) => {
            if (field.type === "boolean") {
                return {
                    ...values,
                    [field.title]: field.value,
                };
            }

            if (field.type === "currency,19,4") {
                return {
                    ...values,
                    [field.title]: field.value ?? null,
                    [mergeCamelized(field.title, "formula")]: field.formula ?? null,
                };
            }

            if (field.type === "date_range") {
                const prefix = field.title.slice(0, -2);
                return {
                    ...values,
                    [mergeCamelized(prefix, EffectField.StartDate)]: field.value?.start ?? null,
                    [mergeCamelized(prefix, EffectField.EndDate)]: field.value?.end ?? null,
                };
            }

            return values;
        },
        {} as Record<string, unknown>,
    );

    const initial = getInitialChangesState(effectCategory, effectCategoryFields, defaultEffectType);
    // changes contains updated values already mapped from field values to api values
    const [changes, setChanges] = useState<Record<string, unknown>>({ ...initial });

    const [hasInvalidCalculationField, setHasInvalidCalculationField] = useState(false);

    const updateSelection = (update: Record<string, unknown>) => {
        const sanitizedUpdate = mapFieldValues(update);
        const { hasChanges, updated } = compareObjects(changes, sanitizedUpdate);
        if (hasChanges && Object.keys(sanitizedUpdate).length > 0) {
            setChanges(updated);
        }
    };

    // Apply changes to the current values of the effect category
    const updatedCategory: any = { ...effectCategory, ...calculationFieldValues, ...changes };
    // Apply the changes to the fields so that the changes are displayed in the dialog
    const uncalculatedFields: any[] = getUncalculatedFields(effectCategory, generations).map((field) =>
        injectValueToField(updatedCategory, changes, field),
    );
    const { visibleFields } = useEffectFields({
        fields: uncalculatedFields,
        effectType: updatedCategory.effectType,
        updateFields: updateSelection,
    });

    const currencies = useCurrencies();
    const processCurrency = useCurrencyContext();

    const { categoryFields, additionalFields } = useCategoryFields({
        effectCategoryFields,
        showCategoryFields,
        allEffectCategoryValues,
        effectCategoryValuesInUse,
        changes,
        updatedCategory,
        additionalFields: {
            // add effectType to calculation of avaliable values
            effectType: {
                allValues: [EffectType.Savings, EffectType.ChangeoverCosts],
                valuesInUse: effectTypes,
            },
            // add currency to calculation as well
            currencyId: {
                allValues: currencies.map(({ id }) => id),
                valuesInUse: currencyIdsInUse,
            },
        },
    });

    const isEdit = "id" in changes;
    const isValid = categoryFields.every((sel) => sel.value != null);
    const effectType = effectCategory?.effectType ?? defaultEffectType;

    const user = useCurrentUser();

    const defaultCurrencyId = effectCategory?.currencyId ?? measure.measureConfig.currencyId ?? user?.currencyId ?? processCurrency.id;

    const dateRange = uncalculatedFields.find((fields) => fields.type === "date_range").value;
    const startDate = moment(dateRange.start);
    const endDate = moment(dateRange.end);
    // Invalid/empty dates are "okay"
    const validDates =
        (!startDate.isValid() || isDateBetween(startDate, FirstDate, endDate) || !endDate.isValid()) &&
        (!endDate.isValid() || endDate.isSameOrBefore(LastDate));

    const showGenerationLinearAlert =
        isEdit &&
        !disabled &&
        generations.find((generation) => effectCategory?.id === generation.effectCategoryId)?.calculationType === CalculationType.NonLinear;

    const [selectedCurrency, setSelectedCurrency] = useState<number>(defaultCurrencyId);

    const validCurrency = selectedCurrency != null && !additionalFields.currencyId?.filteredValues.includes(selectedCurrency);

    const onCurrencyUpdated = (newValue: number) => {
        setSelectedCurrency(newValue);
    };

    const contextCurrency = currencies.find((c) => c.id === selectedCurrency) ?? processCurrency;

    const saveEffectCategory = () => {
        // Do nothing, if form not completed yet
        if (!isValid || hasInvalidCalculationField || disabled || !validDates || !validCurrency) {
            return;
        }
        // do not send invisible calculation fields to backend
        const { id, effectType, ...changesToSend } = Object.entries(changes)
            .filter(([fieldName]) => {
                const isVisibleField = visibleFields.some((field) => field.title === fieldName);
                const isCalculationField = uncalculatedFields.some((field) => field.title === fieldName);
                const isEffectCategoryField = !isCalculationField && fieldName !== EffectField.HasInitial && fieldName !== "id";
                const isEffectCategoryFieldWithChanges =
                    isEffectCategoryField && effectCategory ? initial[fieldName] !== changes[fieldName] : isEffectCategoryField;
                return isEffectCategoryFieldWithChanges || isVisibleField || fieldName === EffectField.HasInitial || fieldName === "id";
            })
            .reduce(
                (allChanges, [fieldName, fieldValue]) => ({
                    ...allChanges,
                    [fieldName]: fieldValue,
                }),
                {} as Record<string, unknown>,
            );

        const effectCategoryFieldTitles = effectCategoryFields.map((ecf) => ecf.title);
        let attributes: Record<string, unknown> = {};
        let calculationValues: Record<string, unknown> = {};
        Object.entries(changesToSend).forEach(([key, value]) => {
            if (effectCategoryFieldTitles.includes(key)) {
                attributes = { ...attributes, [key]: value };
            } else {
                calculationValues = { ...calculationValues, [key]: value };
            }
        });

        // Strict boolean check needed so that no changes are not interpreted as false
        if (changesToSend[EffectField.HasInitial] === false) {
            // When only effects are entered, the initial value should be wiped on save
            calculationValues[EffectField.Initial] = null;
            calculationValues[EffectField.Target] = null;
            calculationValues[EffectField.PriceHike] = null;
        }
        if (changesToSend[EffectField.HasInitial] === true) {
            calculationValues[EffectField.Effect] = null;
        }

        const isCurrencyChanged = selectedCurrency !== effectCategory?.currencyId;

        onSave({ id, effectType, attributes, calculationValues, currencyId: isCurrencyChanged ? selectedCurrency : null });
        onClose();
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={onClose}
            translate={translate}
            primary={isEdit ? translationKeys.VDLANG_SAVE : "Add"}
            primaryDisabled={!isValid || hasInvalidCalculationField || disabled || !validDates || !validCurrency}
            onPrimary={saveEffectCategory}
            action={isEdit ? "edit" : "create"}
            item={`effect_type_${effectType}`}
            titleLabel={
                withBadge && gateTaskConfig.calculationIdentifier != null ? (
                    <GenerationChip
                        variant="light"
                        label={translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${gateTaskConfig.calculationIdentifier}`)}
                    />
                ) : null
            }
            shapedHeader
        >
            <CurrencyContextProvider currency={contextCurrency}>
                <EffectCategoryForm
                    categoryFields={categoryFields}
                    updateFieldHandler={updateSelection}
                    translate={translate}
                    processName={processName}
                    lang={lang}
                    client={client}
                    calculationFields={uncalculatedFields}
                    onSubmit={saveEffectCategory}
                    disabled={disabled}
                    showCategoryFields={showCategoryFields}
                    showCalculationFields={showCalculationFields}
                    effectType={effectType}
                    showGenerationLinearAlert={showGenerationLinearAlert}
                    updateHasInvalidCalculation={setHasInvalidCalculationField}
                    measureId={measure.id}
                    currencyId={selectedCurrency}
                    currencyUpdated={onCurrencyUpdated}
                    currencyIdsInUse={additionalFields.currencyId?.filteredValues ?? []}
                />
            </CurrencyContextProvider>
        </ActionItemDialog>
    );
};
export default EffectCategoryDialog;
