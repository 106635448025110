import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CurrencySelect from "../../../../components/input/select/CurrencySelect";
import { useCurrencies } from "../../../../domain/currencies";
import { useIsDesktop } from "../../../../lib/mobile";
import { translationKeys } from "../../../../translations/main-translations";

interface CurrencyFormProps {
    currencyId: number;
    currencyUpdated: (newValue: number) => void;
    currencyIdsInUse: number[];
    disabled?: boolean;
}

const CurrencyForm = ({ currencyId, currencyUpdated, currencyIdsInUse, disabled }: CurrencyFormProps) => {
    const { t: translate } = useTranslation();
    const currencies = useCurrencies();
    const isDesktop = useIsDesktop();

    const onCurrencyUpdate = (value: number | null) => {
        if (value != null) {
            currencyUpdated(value);
        }
    };

    return (
        <Grid container spacing={isDesktop ? 2 : 0}>
            <Grid item xs={12}>
                <CurrencySelect
                    currencies={currencies.filter((c) => !currencyIdsInUse.includes(c.id))}
                    disabled={disabled}
                    value={currencyId != null && !currencyIdsInUse.includes(currencyId) ? currencyId : null}
                    onChange={onCurrencyUpdate}
                    label={translate(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_CURRENCY_SELECT_LABEL)}
                    required={true}
                    inputId="currencyId"
                    menuPortalTarget={document.body}
                />
            </Grid>
        </Grid>
    );
};

export default CurrencyForm;
