import { Button, Fade, FormControlLabel, Stack, Switch, Typography, formControlLabelClasses, styled } from "@mui/material";
import {
    AclPermissions,
    AttributeTitle,
    DecisionDto,
    EffectCategoryDto,
    GateStatus,
    GateTaskConfigDto,
    GateTaskDto,
    InvalidMeasurePropertiesDto,
    UserDto,
} from "api-shared";
import { TFunction } from "i18next";
import moment from "moment";
import { useState } from "react";
import { useCurrentClient } from "../../domain/client";
import { useGroups } from "../../domain/group";
import { useMeasurePermissionsAddGroupMutation } from "../../domain/measure/permission";
import { MeasureField } from "../../lib/fields";
import { translationKeys } from "../../translations/main-translations";
import GateCompleteMessage from "./GateCompleteMessage";
import GateCompletionHint from "./GateCompletionHint";

const Root = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(0.5, 2),
    margin: 0,
    width: "100%",
    ...theme.typography.caption,
    flexWrap: "nowrap",
    minHeight: theme.spacing(7.5),
}));

const Grow = styled("div")(({ theme }) => ({
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
        // no need for spacer on mobile, because flexDirection is column
        display: "none",
    },
}));

const DaysInLevelText = styled(Typography)({
    flexShrink: 0,
});

const CompleteButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        // on mobile, all alements should be aligned-flex start, except the button
        alignSelf: "flex-end",
    },
}));

const SwitchLabel = styled(FormControlLabel)(({ theme }) => ({
    [`& .${formControlLabelClasses.label}`]: {
        ...theme.typography.caption,
    },
}));

const HoverCompletionHint = styled(GateCompletionHint)(({ theme }) => ({
    opacity: 0,
    transition: theme.transitions.create("opacity", { duration: theme.transitions.duration.enteringScreen }),
}));

interface GateFooterProps {
    gateTask: GateTaskDto;
    allGateTasks: GateTaskDto[];
    translate: TFunction;
    predecessorCompletedAt: Date | null;
    decision: DecisionDto | null;
    users: UserDto[];
    disabled?: boolean;
    completeGate: () => void;
    measureNotEditable: boolean;
    invalidProperties: InvalidMeasurePropertiesDto;
    fields: MeasureField[];
    effectCategories: EffectCategoryDto[];
    isActive: boolean;
    gateTaskConfigs: GateTaskConfigDto[];
    isAllowedToComplete: boolean;
    isGateRequestInProgress: boolean;
    userCanDecide: boolean;
    canMakePublic: boolean;
}

export function getDaysBetween(older: Date, newer: Date) {
    return moment(newer).startOf("day").diff(moment(older).startOf("day"), "days", false);
}

const GateFooter = ({
    gateTask,
    allGateTasks,
    translate,
    predecessorCompletedAt,
    decision,
    users,
    disabled,
    completeGate,
    measureNotEditable,
    invalidProperties,
    fields,
    effectCategories,
    isActive,
    gateTaskConfigs,
    isAllowedToComplete,
    isGateRequestInProgress,
    userCanDecide,
    canMakePublic,
}: GateFooterProps) => {
    const [makePublic, setMakePublic] = useState(true);

    const client = useCurrentClient();
    const groups = useGroups();
    const measurePermissionsAddGroup = useMeasurePermissionsAddGroupMutation();

    const startedAt = predecessorCompletedAt ?? gateTask.createdAt;

    if (gateTask.status === GateStatus.STATUS_COMPLETED) {
        return (
            <GateCompleteMessage
                gateTask={gateTask}
                translate={translate}
                startedAt={startedAt}
                users={users}
                gateTaskConfigs={gateTaskConfigs}
            />
        );
    }

    const getDaysInCurrentLevel = () => {
        const days = getDaysBetween(startedAt, new Date());
        switch (days) {
            case 0:
                return translate(translationKeys.VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_SINCE_TODAY);
            case 1:
                return translate(translationKeys.VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_SINCE_YESTERDAY);
            default:
                return translate(translationKeys.VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_FOR, { days });
        }
    };

    const discardStatementFilled = fields.find((o) => o.title === AttributeTitle.DiscardStatement)?.value != null;
    const discardReasonFilled = fields.find((o) => o.title === AttributeTitle.DiscardReason)?.value != null;
    const isDiscard = decision !== null ? !decision?.isApproved && discardReasonFilled && discardStatementFilled : false;
    const canComplete =
        !disabled && isAllowedToComplete && (gateTask.validated === true || isDiscard) && !isGateRequestInProgress && userCanDecide;
    const groupName = groups.data?.find((g) => g.id === client.defaultMeasureGroupId)?.name;
    const showMakePublic = canComplete && client.defaultMeasureGroupId !== null && canMakePublic && groupName !== undefined;

    const completeGateClicked = () => {
        completeGate();

        if (client.defaultMeasureGroupId === null) {
            return;
        }

        if (showMakePublic && makePublic) {
            measurePermissionsAddGroup.mutate({
                entityId: gateTask.measureId,
                id: client.defaultMeasureGroupId,
                permission: AclPermissions.Read,
            });
            measurePermissionsAddGroup.mutate({
                entityId: gateTask.measureId,
                id: client.defaultMeasureGroupId,
                permission: AclPermissions.Update,
            });
        }
    };

    return (
        <Root spacing={2} direction={{ xs: "column", md: "row" }} alignItems={{ xs: "flex-start", md: "center" }}>
            {isActive && (
                <DaysInLevelText variant="inherit" color="textSecondary">
                    {getDaysInCurrentLevel()}
                </DaysInLevelText>
            )}
            <Grow />
            {!measureNotEditable &&
                (isActive ? (
                    <GateCompletionHint
                        isAllowedToComplete={isAllowedToComplete}
                        invalidProperties={invalidProperties}
                        fields={fields}
                        gateTask={gateTask}
                        allGateTasks={allGateTasks}
                        effectCategories={effectCategories}
                        gateTaskConfigs={gateTaskConfigs}
                        translate={translate}
                        userCanDecide={userCanDecide}
                    />
                ) : (
                    <HoverCompletionHint
                        isAllowedToComplete={isAllowedToComplete}
                        invalidProperties={invalidProperties}
                        fields={fields}
                        gateTask={gateTask}
                        allGateTasks={allGateTasks}
                        effectCategories={effectCategories}
                        gateTaskConfigs={gateTaskConfigs}
                        translate={translate}
                        userCanDecide={userCanDecide}
                    />
                ))}
            {showMakePublic && (
                <Fade in={showMakePublic}>
                    <SwitchLabel
                        // Fade does not remove dom node, resulting in pointer cursor even if not visible
                        // diable component if not shown to resolve cursor issues
                        disabled={!canComplete}
                        control={<Switch size="small" checked={makePublic} onChange={(e) => setMakePublic(e.target.checked)} />}
                        label={translate("automaticVisibilityChange", { group: groupName })}
                    />
                </Fade>
            )}
            <CompleteButton
                variant="contained"
                color="primary"
                disabled={!canComplete}
                onClick={completeGateClicked}
                data-testid="completeGate"
            >
                {decision != null ? translate(`decision_result_${Number(decision.isApproved)}`) : translate("finishStep")}
            </CompleteButton>
        </Root>
    );
};

export default GateFooter;
