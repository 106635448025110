import { DataTypeProvider } from "@devexpress/dx-react-grid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip, chipClasses, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import Effect from "../../../../components/Effect";
import { withPercentage } from "../../../../lib/formatters";
import { translationKeys } from "../../../../translations/main-translations";

const EffectWithWarning = styled(Effect)(({ theme }) => ({
    [`& .${chipClasses.deleteIcon}, & .${chipClasses.deleteIcon}:hover`]: {
        // Change Chip's deleteIcon color from grey to warning color
        color: theme.palette.warning.main,
        cursor: "inherit",
    },
}));

const EffectFormatter = ({
    value: { value, relativeValue, currency, hasIgnoredValues },
    column,
    row,
}: DataTypeProvider.ValueFormatterProps) => {
    const { t: translate } = useTranslation();

    const isSummary = row === undefined; // this is the summary
    const { lang } = column as any;

    const suffixText = relativeValue != null && relativeValue !== 0 ? ` / ${withPercentage(relativeValue * 100, lang, 1)}` : undefined;
    const suffixIcon =
        isSummary && hasIgnoredValues ? (
            <Tooltip title={translate(translationKeys.VDLANG_CALCULATION_GATE_SUMMARY_IGNORED_VALUES_HINT)}>
                <InfoOutlinedIcon />
            </Tooltip>
        ) : undefined;

    const effect = (
        <EffectWithWarning
            withTooltip
            value={value}
            nullValue=""
            suffixText={suffixText}
            suffixIcon={suffixIcon}
            variant={isSummary ? "chip" : "text"}
            currency={currency}
        />
    );

    return isSummary ? effect : <strong>{effect}</strong>;
};

export default EffectFormatter;
