import { TableSummaryRow } from "@devexpress/dx-react-grid-material-ui";
import { styled, Typography } from "@mui/material";
import MoneyChip from "../../../../components/MoneyChip";

const BoldMoneyChip = styled(MoneyChip)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
}));

const SummaryItem = ({ type, value, children }: TableSummaryRow.ItemProps) => {
    switch (type) {
        case "sum":
            return <BoldMoneyChip value={value ?? 0} />;
        case "maxrange":
            // regular font weight
            return (
                <Typography color="textPrimary" component="span">
                    {children}
                </Typography>
            );
        default:
            // default to fontWeightBold
            return (
                <Typography color="textPrimary" component="span">
                    <strong>{children}</strong>
                </Typography>
            );
    }
};

export default SummaryItem;
