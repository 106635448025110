import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    EffectCategoryDto,
    EffectCategoryFields,
    EffectCategoryHistoryEntryDto,
    EffectCategoryListDto,
    EffectHistoryEntryDto,
    EffectType,
    GenerationHistoryEntryDto,
} from "api-shared";
import { apiDelete, apiGet, apiPatch, apiPost } from "../lib/api";
import { effectKeys } from "./effect";
import { generationKeys } from "./generation";
import { MeasureDetailQueryKeys } from "./measure/detail";
import { processHistoryKeys } from "./process-history";
import { ProcessPulseQueryKeys } from "./process-pulse";
import { ReportingQueryKeys } from "./reporting";

const EFFECT_CATEGORIES_PATH = "effect-categories";

export const effectCategoriesKeys = {
    forMeasure: (measureId: number) => [`${EFFECT_CATEGORIES_PATH}?measureId=${measureId}`],
};

export interface IAddEffectCategoryInputDto {
    measureId: number;
    categoryFields: Record<string, unknown>;
    effectType: EffectType;
    calculationFields: Record<string, unknown>;
    currencyId: number;
}

export interface IUpdateEffectCategoryInputDto {
    effectCategoryId: number;
    measureId: number;
    categoryFields: EffectCategoryFields;
    currencyId: number;
}

export interface ICopyFromPreviousGenerationInputDto {
    id?: number;
    measureId: number;
    gateTaskId: number;
}

export interface IRemoveEffectCategoryInputDto {
    id: number;
    measureId: number;
}

export const useEffectCategories = (measureId: number) => {
    return useQuery({
        queryKey: effectCategoriesKeys.forMeasure(measureId),
        queryFn: ({ signal }) => apiGet<EffectCategoryListDto>(`${EFFECT_CATEGORIES_PATH}?measureId=${measureId}`, { signal }),
    });
};

export const useAddEffectCategory = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: IAddEffectCategoryInputDto) => apiPost<EffectCategoryDto>(EFFECT_CATEGORIES_PATH, data),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
            queryClient.invalidateQueries(generationKeys.forMeasure(measureId));
            queryClient.invalidateQueries(effectKeys.forMeasure(measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
        },
    });
};

export const useUpdateEffectCategory = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ effectCategoryId, categoryFields, currencyId }: IUpdateEffectCategoryInputDto) =>
            apiPatch<EffectCategoryDto>(`${EFFECT_CATEGORIES_PATH}/${effectCategoryId}`, { categoryFields, currencyId }),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
        },
    });
};

export const useCopyValuesFromPreviousGeneration = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: ICopyFromPreviousGenerationInputDto) => apiPost<EffectCategoryListDto>(`${EFFECT_CATEGORIES_PATH}/copy`, data),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
            queryClient.invalidateQueries(generationKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId), undefined);
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
            queryClient.invalidateQueries(effectKeys.forMeasure(measureId));
        },
    });
};

export const useRemoveEffectCategory = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }: IRemoveEffectCategoryInputDto) => apiDelete(`${EFFECT_CATEGORIES_PATH}/${id}`),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
            queryClient.invalidateQueries(effectKeys.forMeasure(measureId));
        },
    });
};

export const useEffectCategoryHistory = (id: number) => {
    const url = `${EFFECT_CATEGORIES_PATH}/${id}/history`;
    return useQuery({
        queryKey: [url],
        queryFn: ({ signal }) =>
            apiGet<{ items: Array<EffectHistoryEntryDto | EffectCategoryHistoryEntryDto | GenerationHistoryEntryDto> }>(url, { signal }),
        enabled: id > 0,
    });
};
