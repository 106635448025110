import { Currency, EffectField, EffectType } from "api-shared";
import { FilledField } from "../../../../lib/fields";
import { noOp } from "../../../../lib/utils";

function getSafeValue(value: unknown): string | number | null {
    return typeof value === "string" || typeof value === "number" ? value : null;
}

function negateField(field: FilledField): FilledField {
    if (field.type !== "currency,19,4") {
        return field;
    }
    const effect = new Currency(field.formula ?? null, field.value);
    const negatedEffect = effect.negate();
    return {
        ...field,
        value: negatedEffect.value,
        formula: negatedEffect.formula,
    };
}

function synchronizeHiddenFields(
    changes: Record<string, unknown>,
    relevantEffect: Currency | null,
    relevantInitial: Currency | null,
    relevantTarget: Currency | null,
    shouldNegate: boolean,
): [Record<string, unknown>, boolean] {
    const synchronizedChanges = { ...changes };

    const hasNewInitial = changes[EffectField.Initial] !== undefined;
    const hasNewTarget = changes[EffectField.Target] !== undefined;
    const hasNewEffect = changes[EffectField.Effect] !== undefined;

    let hadTargetSynced = false;
    let hadEffectSynced = false;

    if (relevantInitial != null && relevantEffect != null && hasNewEffect) {
        // effect changed, sync target to match new initial/effect combination
        // use non-inverted values here, but use add method in case of extraCosts. This makes the formula as close to the user
        // input a spossible
        const newTarget = shouldNegate ? relevantInitial.add(relevantEffect) : relevantInitial.subtract(relevantEffect);
        synchronizedChanges[EffectField.Target] = newTarget.toValue();
        hadTargetSynced = true;
    }

    if (relevantInitial != null && relevantTarget != null && (hasNewInitial || hasNewTarget)) {
        // combination of target/initial changed, sync effect to match new initial/target
        // for extra costs the new effect formula from the user perspective should be target-initial
        // e.g.  initial=5000, target=6000 => extra costs of 1000 are displayed, but an effect of -1000 is saved
        // formula result: =(target)-(initial) is displayed, but =-((target)-(initial)) is saved
        const newEffect = shouldNegate ? relevantTarget.subtract(relevantInitial).negate() : relevantInitial.subtract(relevantTarget);
        synchronizedChanges[EffectField.Effect] = newEffect.toValue();
        hadEffectSynced = true;
    }
    return [synchronizedChanges, hadTargetSynced || hadEffectSynced];
}

interface IUseCustomizedEffectFieldProps {
    fields: FilledField[];
    updateField: (changes: Record<string, unknown>) => void;
    customizedTitle: string;
}

export function useCustomizedEffectField({ customizedTitle, fields, updateField }: IUseCustomizedEffectFieldProps) {
    function updateCustomizedEffectField(changes: Record<string, unknown>) {
        const { [customizedTitle]: effectChange, ...otherChanges } = changes;

        if (effectChange !== undefined) {
            otherChanges[EffectField.Effect] = effectChange;
        }
        updateField(otherChanges);
    }
    const customizedFields = fields.map((field) => (field.title === EffectField.Effect ? { ...field, title: customizedTitle } : field));
    return { fields: customizedFields, updateFields: updateCustomizedEffectField };
}

interface IUseEffectFieldsProps {
    fields: FilledField[];
    effectType: EffectType;
    updateFields: (changes: Record<string, unknown>) => void;
    updateHasInvalidCalculation?: (newValidation: boolean) => void;
}

const useEffectFields = ({ fields, effectType, updateFields, updateHasInvalidCalculation = noOp }: IUseEffectFieldsProps) => {
    const shouldNegate = effectType === EffectType.ChangeoverCosts;
    const hasInitialField = fields.find(({ title }) => title === EffectField.HasInitial);

    const updateCalculationField = (changes: Record<string, unknown>) => {
        const targetField = fields.find(({ title }) => title === EffectField.Target);
        const initialField = fields.find(({ title }) => title === EffectField.Initial);

        const currentInitial = new Currency(initialField?.formula ?? null, initialField?.value);
        const currentTarget = new Currency(targetField?.formula ?? null, targetField?.value);

        const newEffect = Currency.fromValue(getSafeValue(changes[EffectField.Effect]));
        const newTarget = Currency.fromValue(getSafeValue(changes[EffectField.Target]));
        const newInitial = Currency.fromValue(getSafeValue(changes[EffectField.Initial]));

        const hasNewInitial = changes[EffectField.Initial] !== undefined;
        const hasNewTarget = changes[EffectField.Target] !== undefined;
        const hasNewEffect = changes[EffectField.Effect] !== undefined;

        const relevantInitial = hasNewInitial ? newInitial : currentInitial;
        const relevantTarget = hasNewTarget ? newTarget : currentTarget;

        let sanitizedChanges = { ...changes };

        if (hasNewEffect) {
            const correctedEffect = newEffect !== null && shouldNegate ? newEffect.negate() : newEffect;
            sanitizedChanges[EffectField.Effect] = correctedEffect?.toValue() ?? null;
        }

        try {
            const [syncedChanges, wasSynced] = synchronizeHiddenFields(
                sanitizedChanges,
                newEffect,
                relevantInitial,
                relevantTarget,
                shouldNegate,
            );
            if (wasSynced) {
                sanitizedChanges = syncedChanges;
                updateHasInvalidCalculation(false);
            }
        } catch (e) {
            updateHasInvalidCalculation(true);
        }
        // propagate changes, even if error happened, so that UI can still update with the new values
        updateFields(sanitizedChanges);
    };

    const hiddenFieldNames: string[] = hasInitialField?.value
        ? [EffectField.Effect, EffectField.HasInitial]
        : [EffectField.Initial, EffectField.Target, EffectField.PriceHike, EffectField.HasInitial];

    const visibleFields = fields
        .filter((field) => !hiddenFieldNames.includes(field.title))
        // show negation of effect fields, when effectType is ExtraCosts
        .map((field) => (shouldNegate && field.title === EffectField.Effect ? negateField(field) : field));

    return {
        hasInitialField,
        visibleFields,
        updateCalculationField,
    };
};

export default useEffectFields;
