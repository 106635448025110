import AddIcon from "@mui/icons-material/AddRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import { Checkbox, Chip, Collapse, FormControlLabel, Grid, Link, Switch, Typography, styled } from "@mui/material";
import Alert from "@mui/material/Alert";
import { ClientDto, EffectField, EffectFilterCurrencyField, EffectType, FeatureFlags } from "api-shared";
import { TFunction } from "i18next";
import { useCallback, useState } from "react";
import FeatureFlag from "../../../../components/FeatureFlag";
import Field from "../../../../components/Field";
import InfoIcon from "../../../../components/icons/InfoIcon";
import { useClientHasFeature } from "../../../../domain/client";
import { FilledField } from "../../../../lib/fields";
import { useIsDesktop } from "../../../../lib/mobile";
import { Language, translationKeys } from "../../../../translations/main-translations";
import useEffectFields, { useCustomizedEffectField } from "./useEffectFields";

const NEGATIVE_VALUE_REGEX = /-/;

const AdditionalFieldChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
}));

const ExpandAdditionalFieldsFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: theme.spacing(),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

interface ICalculationFormProps {
    translate: TFunction;
    updateFieldHandler: (changes: Record<string, unknown>) => void;
    processName: string;
    client: ClientDto;
    calculationFields: FilledField[];
    lang: Language;
    disabled: boolean;
    effectType: EffectType;
    updateHasInvalidCalculation: (newValidation: boolean) => void;
    measureId: number;
}

const CalculationForm = ({
    translate,
    updateFieldHandler: updateFieldProps,
    processName,
    client,
    calculationFields,
    lang,
    disabled,
    effectType,
    updateHasInvalidCalculation,
    measureId,
}: ICalculationFormProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [additionalFields, setAdditionalFields] = useState<Record<string, boolean>>({
        [EffectField.PriceHike]: false,
    });

    const isDesktop = useIsDesktop();
    const hasExpectedPriceIncreaseFeature = useClientHasFeature(FeatureFlags.FEATURE_EXPECTED_PRICE_INCREASE);
    const enabledCalculationFields = calculationFields.filter(
        ({ title }) => title !== EffectField.PriceHike || hasExpectedPriceIncreaseFeature,
    );

    // remember if any of the hidden, updated fields resulted in an invalid currency value
    const [hasInvalidNumberRange, setHasInvalidNumberRange] = useState(false);

    const updateCalculationValidation = useCallback(
        (newValidation: boolean) => {
            updateHasInvalidCalculation(newValidation);
            setHasInvalidNumberRange(newValidation);
        },
        [updateHasInvalidCalculation],
    );

    const {
        hasInitialField,
        visibleFields: uncustomizedFields,
        updateCalculationField,
    } = useEffectFields({
        effectType,
        updateHasInvalidCalculation: updateCalculationValidation,
        fields: enabledCalculationFields,
        updateFields: updateFieldProps,
    });

    const { fields: modifiedEffectFields, updateFields } = useCustomizedEffectField({
        customizedTitle: `effect_type_${effectType}`,
        fields: uncustomizedFields,
        updateField: updateCalculationField,
    });

    const hasInitial = hasInitialField ? hasInitialField.value : effectType === EffectType.Savings;

    const warnOnNegativeValue = (value: unknown) => {
        // This is not actually checking if the value is negative
        // Instead we just check if there is a "-" in the text field at the moment
        if (typeof value !== "string" || !NEGATIVE_VALUE_REGEX.test(value)) {
            return null;
        }

        return translate(translationKeys.VDLANG_CURRENCY_INPUT_NEGATIVE_NUMBER, { effectType: translate(`effect_type_${effectType}`) });
    };

    const hiddenAdditionalFields = Object.entries(additionalFields)
        // Remove PriceHike from additional fields drop down if not enabled
        .filter(([field, _]) => field !== EffectFilterCurrencyField.PriceHike || hasExpectedPriceIncreaseFeature)
        // Fields that are not active and don't have a value should be hidden
        .filter(([field, isActive]) => !isActive && !modifiedEffectFields.some((f) => f.title === field && f.value != null))
        .map(([field]) => field);

    return (
        <Grid container spacing={isDesktop ? 2 : 0}>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasInitial}
                            onChange={(event) => updateFields({ [EffectField.HasInitial]: event.target.checked })}
                            data-testid="hasInitialSwitch"
                        />
                    }
                    label={
                        <>
                            {`${translate(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_HAS_INITIAL)} `}
                            <InfoIcon
                                title={translate(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_HAS_INITIAL_HINT, {
                                    effectType: translate(`effect_type_${effectType}`),
                                })}
                            />
                        </>
                    }
                    disabled={disabled}
                />
            </Grid>
            {hasInvalidNumberRange && (
                <Grid item xs={12}>
                    <Alert severity="error">{translate(translationKeys.VDLANG_CURRENCY_INPUT_INVALID_NUMBER_RANGE)}</Alert>
                </Grid>
            )}
            {modifiedEffectFields
                .filter((field) => !hiddenAdditionalFields.some((hiddenField) => field.title === hiddenField))
                .map((field) => (
                    <Field
                        key={field.title}
                        propName={field.title}
                        field={field}
                        disabled={disabled}
                        client={client}
                        translate={translate}
                        updateHandler={updateFields}
                        processName={processName}
                        lang={lang}
                        dense={isDesktop}
                        getCustomWarning={field.title.startsWith("effect_type") ? warnOnNegativeValue : undefined}
                        action={
                            field.title === EffectField.PriceHike ? (
                                <Link
                                    color="error"
                                    variant="body2"
                                    component="button"
                                    type="button"
                                    disabled={disabled}
                                    onClick={() => {
                                        updateFields({ [field.title]: null });
                                        setAdditionalFields({
                                            [EffectField.PriceHike]: false,
                                        });
                                    }}
                                >
                                    {translate(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_REMOVE_FIELD)}
                                </Link>
                            ) : undefined
                        }
                        testId={field.title}
                        measureId={measureId}
                    />
                ))}
            {hasInitial && (
                // Completely hide the collape if price hike feature is disabled
                // Change this when adding new additional fields
                <FeatureFlag feature={FeatureFlags.FEATURE_EXPECTED_PRICE_INCREASE}>
                    <Grid item xs={12}>
                        <ExpandAdditionalFieldsFormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    color="default"
                                    disableRipple
                                    icon={<ChevronRightIcon color={disabled ? "disabled" : "action"} />}
                                    checkedIcon={<ExpandMoreIcon />}
                                />
                            }
                            label={translate(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_ADDITIONAL_FIELDS)}
                            checked={isExpanded}
                            disabled={disabled}
                            onChange={(event, checked) => setIsExpanded(checked)}
                        />
                        <Collapse in={isExpanded}>
                            <div>
                                {hiddenAdditionalFields.length > 0 ? (
                                    hiddenAdditionalFields.map((field) => (
                                        <AdditionalFieldChip
                                            key={field}
                                            clickable
                                            size="small"
                                            icon={<AddIcon />}
                                            label={translate(field)}
                                            onClick={() =>
                                                setAdditionalFields({
                                                    ...additionalFields,
                                                    [field]: true,
                                                })
                                            }
                                        />
                                    ))
                                ) : (
                                    <Typography color="textSecondary">
                                        {translate(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_NO_ADDITIONAL_FIELDS)}
                                    </Typography>
                                )}
                            </div>
                        </Collapse>
                    </Grid>
                </FeatureFlag>
            )}
        </Grid>
    );
};

export default CalculationForm;
