import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AclPermissions, CompleteGateTaskDto, GateStatus, GateTaskDto } from "api-shared";
import { useDispatch } from "react-redux";
import { apiPatch, apiPost } from "../lib/api";
import { usePredecessorGateTaskConfig } from "./measure-config";
import { NotificationQueryKeys } from "./measure-notifications";
import { MeasureDetailQueryKeys } from "./measure/detail";
import { MeasurePermissionsQueryKeys } from "./measure/permission";
import { processHistoryKeys } from "./process-history";
import { ProcessPulseQueryKeys } from "./process-pulse";
import { ReportingQueryKeys } from "./reporting";

export const COMPLETE_GATE_SUCCEEDED = "COMPLETE_GATE_SUCCEEDED";

export interface IUpdateGateTaskDto {
    gateTaskId: number;
    assignedToId?: number | null;
    duedate?: string | null;
    startDate?: string | null;
    remindAt?: string | null;
    startRemindAt?: string | null;
}

export const useUpdateGateTaskMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ gateTaskId, ...changes }: IUpdateGateTaskDto) => apiPatch<GateTaskDto>(`gate-tasks/${gateTaskId}`, changes),
        onSuccess: (response: GateTaskDto, { gateTaskId, ...changes }) => {
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(response.measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(response.measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(response.measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(response.measureId));
            queryClient.invalidateQueries(
                MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Read, measureId: response.measureId }),
            );
            queryClient.invalidateQueries(
                MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Update, measureId: response.measureId }),
            );
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(response.measureId));
        },
    });
};

export const useCompleteGateTaskMutation = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ gateTaskId, ...changes }: CompleteGateTaskDto) => apiPost<GateTaskDto>(`gate-tasks/${gateTaskId}/complete`, changes),
        onSuccess: (response: GateTaskDto) => {
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(response.measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(response.measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(response.measureId));
            queryClient.invalidateQueries(
                MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Read, measureId: response.measureId }),
            );
            queryClient.invalidateQueries(
                MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Update, measureId: response.measureId }),
            );
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(response.measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);

            dispatch({ type: COMPLETE_GATE_SUCCEEDED, response });
        },
    });
};

export const usePredecessorGateTask = (gateTasks: GateTaskDto[], measureConfigId: number, gateTaskConfigId: number) => {
    const predecessorConfigId = usePredecessorGateTaskConfig(measureConfigId, gateTaskConfigId);
    return gateTasks.find((gt) => gt.gateTaskConfigId === predecessorConfigId);
};

export const useGateTaskIsActive = (gateTasks: GateTaskDto[], measureConfigId: number, gateTaskConfigId: number) => {
    const predecessor = usePredecessorGateTask(gateTasks, measureConfigId, gateTaskConfigId);
    const gateTask = gateTasks.find((gt) => gt.gateTaskConfigId === gateTaskConfigId);
    return (predecessor == null || predecessor.status === GateStatus.STATUS_COMPLETED) && gateTask?.status === GateStatus.STATUS_OPEN;
};

export const useGateTasksForUser = (gateTasks: GateTaskDto[], userId?: number) => {
    return gateTasks.filter(({ assignedToId }) => userId === assignedToId);
};
