import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { styled } from "@mui/material";
import {
    CalculationHistoryType,
    EffectCategoryHistoryEntryDto,
    EffectField,
    EffectHistoryEntryDto,
    EffectType,
    GateTaskConfigDto,
    GateTaskDto,
    GateTaskType,
    GenerationDto,
    GenerationHistoryEntryDto,
    HistoryEventType,
    nonNullable,
} from "api-shared";
import { TFunction } from "i18next";
import { useMemo } from "react";
import LoadingAnimation from "../../../../components/loading/LoadingAnimation";
import { useAllUsers } from "../../../../domain/users";
import useCurrency, { CurrencyFormatter } from "../../../../hooks/useCurrency";
import useTimezone, { DateTimeFormatter } from "../../../../hooks/useTimezone";
import { reportError } from "../../../../infrastructure/sentry";
import { formatUserFromId } from "../../../../lib/formatters";
import { attributeName, captureType, effectDate, formatCalculationValue, isMatching } from "../../../../lib/history";
import { translationKeys } from "../../../../translations/main-translations";
import TooltipFormatter from "./TooltipFormatter";

const TableHeaderCell = styled(TableHeaderRow.Cell)(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.divider}`,
}));

const TableCell = styled(Table.Cell)(({ theme }) => ({
    padding: theme.spacing(1),
}));

function determineEffectSign(value: string | number | null, effectType: EffectType): number | null {
    if (value === null) {
        return value;
    }
    return effectType === EffectType.ChangeoverCosts ? +value * -1 : +value;
}

function getGenerationLabel(
    entry: EffectHistoryEntryDto | EffectCategoryHistoryEntryDto | GenerationHistoryEntryDto,
    gateTaskConfigs: GateTaskConfigDto[],
    generations: GenerationDto[],
    gateTasks: GateTaskDto[],
    translate: TFunction,
) {
    if (entry.historyType === CalculationHistoryType.EffectCategory) {
        const firstCalculationIdentifier = [...gateTaskConfigs]
            .sort((a, b) => a.order - b.order)
            .filter((gtc) => gtc.type === GateTaskType.Calculation)
            .map((gtc) => gtc.calculationIdentifier)
            .find(nonNullable);

        return firstCalculationIdentifier != null
            ? translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${firstCalculationIdentifier}`)
            : "";
    }

    const generation = generations.find(({ id }) => entry.generationId === id);
    const gateTask = generation ? gateTasks.find(({ id }) => id === generation.gateTaskId) : undefined;
    const gateTaskConfig = gateTask ? gateTaskConfigs.find(({ id }) => id === gateTask.gateTaskConfigId) : undefined;

    return gateTaskConfig?.calculationIdentifier != null
        ? translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${gateTaskConfig.calculationIdentifier}`)
        : "";
}

function getMessageFromEntry(
    item: EffectHistoryEntryDto | EffectCategoryHistoryEntryDto | GenerationHistoryEntryDto,
    effectType: EffectType,
    translate: TFunction,
    formatDate: DateTimeFormatter,
    formatCurrency: CurrencyFormatter,
    currencyIsoCode: string | undefined,
) {
    let event = null;
    switch (item.historyType) {
        case CalculationHistoryType.EffectCategory:
            event = getEventFromEffectCategoryEntry(item, translate);
            break;
        case CalculationHistoryType.Generation:
            event = getEventFromGenerationEntry(item, translate, formatDate, formatCurrency, currencyIsoCode);
            break;
        default:
            event = getEventFromEffectEntry(item, translate, formatCurrency, formatDate, currencyIsoCode);
    }

    if (event == null) {
        // maybe a case has been forgotten, or there is bad history data. Report this, so it can be investigated
        const { historyType, operationType } = item;
        reportError(new Error("Unknown calculation history entry"), {
            extras: {
                historyType: String(historyType),
                operationType: String(operationType),
                previousValue: String(item.previousValue),
                newValue: String(item.newValue),
            },
        });
        // this entry will be hidden
        return null;
    }

    const placeholderData = event.getData != null ? event.getData(item as any, effectType) : {};
    return translate(`calculation_history.${event.key}`, placeholderData);
}

function getEventFromEffectCategoryEntry(item: EffectCategoryHistoryEntryDto, translate: TFunction) {
    // order of items here is important, the first match is used later
    // -> events should be ordered from most specific to least specific
    const events = [
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_SAVINGS,
            attribute: "effect_type",
            newValue: "1",
        },
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_EXTRACOSTS,
            attribute: "effect_type",
            newValue: "2",
        },
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_CURRENCY,
            attribute: "currency_id",
            getData: (item: EffectCategoryHistoryEntryDto) => ({
                newValue: item.newValue,
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_UPDATE_CURRENCY,
            attribute: "currency_id",
            getData: (item: EffectCategoryHistoryEntryDto) => ({
                newValue: item.newValue,
                oldValue: item.previousValue,
            }),
        },
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_VALUE,
            getData: (item: EffectCategoryHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                newValue: item.newValue,
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_UPDATE_VALUE,
            getData: (item: EffectCategoryHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                newValue: item.newValue,
                previousValue: item.previousValue,
            }),
        },
    ];

    return events.find(
        (event) =>
            event.type === item.operationType &&
            isMatching(event.attribute, item.attribute) &&
            isMatching(event.newValue, `${item.newValue}`),
    );
}

function getEventFromEffectEntry(
    item: EffectHistoryEntryDto,
    translate: TFunction,
    formatCurrency: CurrencyFormatter,
    formatDate: DateTimeFormatter,
    currencyIsoCode: string | undefined,
) {
    const events = [
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_FROM_EMPTY,
            attribute: EffectField.Effect,
            getData: (item: EffectHistoryEntryDto, effectType: EffectType) => ({
                attributeName: translate(`effect_type_${effectType}`),
                newValue: formatCalculationValue(item.newValue, formatDate, formatCurrency, currencyIsoCode),
                effectDate: effectDate(item),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_UPDATE,
            attribute: EffectField.Effect,
            getData: (item: EffectHistoryEntryDto, effectType: EffectType) => ({
                attributeName: translate(`effect_type_${effectType}`),
                previousValue: formatCalculationValue(item.previousValue, formatDate, formatCurrency, currencyIsoCode),
                newValue: formatCalculationValue(item.newValue, formatDate, formatCurrency, currencyIsoCode),
                effectDate: effectDate(item),
            }),
        },
        {
            type: HistoryEventType.DELETE,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_TO_EMPTY,
            attribute: EffectField.Effect,
            getData: (item: EffectHistoryEntryDto, effectType: EffectType) => ({
                attributeName: translate(`effect_type_${effectType}`),
                previousValue: formatCalculationValue(item.previousValue, formatDate, formatCurrency, currencyIsoCode),
                effectDate: effectDate(item),
            }),
        },
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_FROM_EMPTY,
            getData: (item: EffectHistoryEntryDto) => ({
                attributeName: attributeName({ ...item, attribute: item.effectType }, translate),
                newValue: formatCalculationValue(item.newValue, formatDate, formatCurrency, currencyIsoCode),
                effectDate: effectDate(item),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_UPDATE,
            getData: (item: EffectHistoryEntryDto) => ({
                attributeName: attributeName({ ...item, attribute: item.effectType }, translate),
                previousValue: formatCalculationValue(item.previousValue, formatDate, formatCurrency, currencyIsoCode),
                newValue: formatCalculationValue(item.newValue, formatDate, formatCurrency, currencyIsoCode),
                effectDate: effectDate(item),
            }),
        },
        {
            type: HistoryEventType.DELETE,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_TO_EMPTY,
            getData: (item: EffectHistoryEntryDto) => ({
                attributeName: attributeName({ ...item, attribute: item.effectType }, translate),
                previousValue: formatCalculationValue(item.previousValue, formatDate, formatCurrency, currencyIsoCode),
                effectDate: effectDate(item),
            }),
        },
    ];

    return events.find((event) => event.type === item.operationType && isMatching(event.attribute, item.effectType));
}

function getEventFromGenerationEntry(
    item: GenerationHistoryEntryDto,
    translate: TFunction,
    formatDate: DateTimeFormatter,
    formatCurrency: CurrencyFormatter,
    currencyIsoCode: string | undefined,
) {
    const getEffectData = (item: GenerationHistoryEntryDto, effectType: EffectType) => ({
        attributeName: translate(`effect_type_${effectType}`),
        newValue: formatCalculationValue(determineEffectSign(item.newValue, effectType), formatDate, formatCurrency, currencyIsoCode),
    });

    const events = [
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_INSERT_HAS_INITIAL,
            attribute: "has_initial",
            getData: (item: GenerationHistoryEntryDto, effectType: EffectType) => ({
                captureType: captureType(item.newValue, effectType, translate),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_HAS_INITIAL,
            attribute: "has_initial",
            getData: (item: GenerationHistoryEntryDto, effectType: EffectType) => ({
                oldCaptureType: captureType(item.previousValue, effectType, translate),
                newCaptureType: captureType(item.newValue, effectType, translate),
            }),
        },
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY,
            attribute: EffectField.Effect,
            previousValue: "",
            getData: getEffectData,
        },
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY,
            previousValue: "",
            getData: (item: GenerationHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                newValue: formatCalculationValue(item.newValue, formatDate, formatCurrency, currencyIsoCode),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY,
            attribute: EffectField.Effect,
            previousValue: "",
            getData: getEffectData,
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_TO_EMPTY,
            attribute: EffectField.Effect,
            newValue: "",
            getData: (item: GenerationHistoryEntryDto, effectType: EffectType) => ({
                attributeName: translate(`effect_type_${effectType}`),
                previousValue: formatCalculationValue(
                    determineEffectSign(item.previousValue, effectType),
                    formatDate,
                    formatCurrency,
                    currencyIsoCode,
                ),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FIELD,
            attribute: EffectField.Effect,
            getData: (item: GenerationHistoryEntryDto, effectType: EffectType) => ({
                attributeName: translate(`effect_type_${effectType}`),
                newValue: formatCalculationValue(
                    determineEffectSign(item.newValue, effectType),
                    formatDate,
                    formatCurrency,
                    currencyIsoCode,
                ),
                previousValue: formatCalculationValue(
                    determineEffectSign(item.previousValue, effectType),
                    formatDate,
                    formatCurrency,
                    currencyIsoCode,
                ),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY,
            previousValue: "",
            getData: (item: GenerationHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                newValue: formatCalculationValue(item.newValue, formatDate, formatCurrency, currencyIsoCode),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_TO_EMPTY,
            newValue: "",
            getData: (item: GenerationHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                previousValue: formatCalculationValue(item.previousValue, formatDate, formatCurrency, currencyIsoCode),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FIELD,
            getData: (item: GenerationHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                newValue: formatCalculationValue(item.newValue, formatDate, formatCurrency, currencyIsoCode),
                previousValue: formatCalculationValue(item.previousValue, formatDate, formatCurrency, currencyIsoCode),
            }),
        },
    ];

    return events.find(
        (event) =>
            event.type === item.operationType &&
            isMatching(event.attribute, item.attribute) &&
            isMatching(event.previousValue, item.previousValue ?? "") &&
            isMatching(event.newValue, item.newValue ?? ""),
    );
}

const getCurrencyIsoCodeForEffectEntry = (
    entry: EffectHistoryEntryDto | EffectCategoryHistoryEntryDto | GenerationHistoryEntryDto,
    sortedByChangedAtCurrencyChanges: CurrencyChange[],
) => {
    if (entry.historyType == CalculationHistoryType.EffectCategory) {
        return undefined;
    }

    const newestCurrencyAtEntryCreation = sortedByChangedAtCurrencyChanges.find((change) => change.changedAt < entry.updatedAt);
    return newestCurrencyAtEntryCreation?.isoCode;
};

interface CurrencyChange {
    isoCode: string;
    changedAt: Date;
}

interface CalculationHistoryTableProps {
    historyEntries: (EffectHistoryEntryDto | EffectCategoryHistoryEntryDto | GenerationHistoryEntryDto)[];
    translate: TFunction;
    gateTaskConfigs: GateTaskConfigDto[];
    generations: GenerationDto[];
    gateTasks: GateTaskDto[];
    effectType: EffectType;
    currencyChanges: CurrencyChange[];
}

const CalculationHistoryTable = ({
    historyEntries,
    translate,
    gateTaskConfigs,
    generations,
    gateTasks,
    effectType,
    currencyChanges,
}: CalculationHistoryTableProps) => {
    const { formatDate, formatTime } = useTimezone();
    const { formatCurrency } = useCurrency();
    const users = useAllUsers();

    const rows = useMemo(() => {
        const unformattedRows = historyEntries
            .map((entry) => ({
                userId: entry.updatedById,
                dateTime: entry.updatedAt,
                date: formatDate(entry.updatedAt),
                time: formatTime(entry.updatedAt),
                user: formatUserFromId(entry.updatedById, users, { translate }),
                generation: getGenerationLabel(entry, gateTaskConfigs, generations, gateTasks, translate),
                message: getMessageFromEntry(
                    entry,
                    effectType,
                    translate,
                    formatDate,
                    formatCurrency,
                    getCurrencyIsoCodeForEffectEntry(entry, currencyChanges),
                ),
            }))
            .map((row) => ({ ...row, generationLabel: row.generation }));

        return unformattedRows.map((row, index) => {
            const sameDate =
                unformattedRows[index - 1]?.dateTime !== undefined && row.date === formatDate(unformattedRows[index - 1]?.dateTime);
            if (!sameDate) {
                return row;
            }
            row.date = "";
            if (row.userId !== unformattedRows[index - 1]?.userId) {
                return row;
            }
            row.user = "";
            const sameTime =
                unformattedRows[index - 1]?.dateTime !== undefined && row.time === formatTime(unformattedRows[index - 1]?.dateTime);
            if (!sameTime) {
                return row;
            }
            row.time = "";
            if (row.generationLabel !== unformattedRows[index - 1]?.generationLabel) {
                return row;
            }
            row.generation = "";
            return row;
        });
    }, [
        historyEntries,
        formatDate,
        formatTime,
        users,
        translate,
        gateTaskConfigs,
        generations,
        gateTasks,
        effectType,
        formatCurrency,
        currencyChanges,
    ]);

    const columns = [
        { name: "date", title: translate("history_column_date") },
        { name: "user", title: translate("history_column_user") },
        { name: "time", title: translate("history_column_time") },
        { name: "generation", title: translate("history_generation") },
        { name: "message", title: translate("history_column_entry") },
    ];

    return historyEntries.length > 0 ? (
        <Grid columns={columns} rows={rows}>
            <DataTypeProvider formatterComponent={TooltipFormatter} for={columns.map((c) => c.name)} />
            <Table columnExtensions={[{ columnName: "message", width: "50%" }]} cellComponent={TableCell} />
            <TableHeaderRow cellComponent={TableHeaderCell} />
        </Grid>
    ) : (
        <LoadingAnimation />
    );
};

export default CalculationHistoryTable;
