import { Chip, ChipProps, alpha, chipClasses, styled } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React from "react";
import withTooltip from "../../../hocs/withTooltip";

// Wrapper component to drop variant prop from Chip so styled component can define a custom variant prop
const VariantlessChip = React.forwardRef<HTMLDivElement, Omit<ChipProps, "variant">>((chipProps, ref) => <Chip {...chipProps} ref={ref} />);

const GenerationChip = styled(VariantlessChip, { shouldForwardProp: (name) => name !== "variant" })<{
    variant?: "default" | "active" | "light";
}>(({ theme, variant }) => ({
    height: "auto",
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 1.33,
    borderRadius: theme.shape.borderRadius,
    "&, &:focus": {
        backgroundColor: blueGrey[50],
    },
    [`&.${chipClasses.clickable}:hover`]: {
        backgroundColor: blueGrey[100],
        color: theme.palette.text.primary,
    },
    [`&.${chipClasses.disabled}`]: {
        opacity: "unset", // cancels the effect of the default disabled class
    },
    [`& .${chipClasses.label}`]: {
        padding: theme.spacing(0.25, 1),
    },
    ...(variant === "active" && {
        "&, &:focus": {
            backgroundColor: blueGrey[600],
            color: theme.palette.common.white,
        },
        [`&.${chipClasses.clickable}:hover`]: {
            backgroundColor: blueGrey[800],
            color: theme.palette.common.white,
        },
    }),
    ...(variant === "light" && {
        "&, &:focus": {
            backgroundColor: alpha(theme.palette.background.paper, 0.2),
            color: "inherit",
        },
    }),
}));

export default withTooltip(GenerationChip);
