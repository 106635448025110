import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import FileCopyIcon from "@mui/icons-material/FileCopyRounded";
import HistoryIcon from "@mui/icons-material/HistoryRounded";
import VisibilityIcon from "@mui/icons-material/VisibilityRounded";
import { alpha, IconButton, ListItemIcon, ListItemText, MenuItem, styled, SvgIconProps } from "@mui/material";
import { TFunction } from "i18next";
import React from "react";
import MoreActionsMenu from "../../../../components/MoreActionsMenu";
import Tooltip from "../../../../components/Tooltip";

interface Action {
    label: string;
    hint?: string;
    icon: React.ComponentType<SvgIconProps>;
    component?: React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
    testId?: string;
}

// width of the gradient in spacing units
const GRADIENT_WIDTH = 7;

const ActionsContainer = styled("div")(({ theme }) => ({
    // parent element is sticky, so it acts as reference (similar to position: relative) object for this element
    // anchor point is vertically centered at the right end of
    position: "absolute",
    right: 0,
    transform: "translateY(-50%)",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    backgroundImage: `linear-gradient(to right, ${alpha(theme.palette.background.paper, 0)}, ${
        theme.palette.background.paper
    } ${theme.spacing(GRADIENT_WIDTH)})`, // gradient width should be aligned with
    paddingRight: theme.spacing(1),
    gap: theme.spacing(),
}));

// empty element that reserves space for the gradient on its parent
// the gradient cannot be set on this item, because the parent needs opaque background
const FadeGradient = styled("div")(({ theme }) => ({
    width: theme.spacing(GRADIENT_WIDTH),
}));

interface IEffectCategoryTableActionsProps {
    disabled?: boolean;
    onEdit?: () => void;
    onCopy?: () => void;
    onRemove?: () => void;
    onHistory?: () => void;
    translate: TFunction;
    isFirstCalculationGate: boolean;
    className?: string;
    maxIcons?: number;
    isSummary: boolean;
    previousGenerationName: string;
    effectType?: string;
}

const EffectCategoryTableActions = ({
    disabled,
    onCopy,
    onEdit,
    onRemove,
    onHistory,
    translate,
    isFirstCalculationGate,
    className,
    isSummary,
    previousGenerationName,
    effectType = "",
}: IEffectCategoryTableActionsProps) => {
    const visibleActions: Action[] = [];
    const hiddenActions: Action[] = [];

    if (onCopy != null && !isFirstCalculationGate && !disabled) {
        visibleActions.push({
            label: translate("Copy"),
            hint: translate(isSummary ? "copy_all_button_hint" : "copy_button_hint", { effectType, previousGenerationName }),
            onClick: onCopy,
            icon: FileCopyIcon,
        });
    }

    if (onEdit != null) {
        visibleActions.push({
            onClick: onEdit,
            icon: disabled ? VisibilityIcon : EditIcon,
            label: disabled ? translate("showActivitiesChanges") : translate("Edit"),
            hint: disabled ? translate("showActivitiesChanges") : translate("Edit"),
            disabled: false,
        });
    }

    if (onHistory != null) {
        hiddenActions.push({
            label: translate("history"),
            icon: HistoryIcon,
            onClick: onHistory,
            disabled: false,
        });
    }

    if (onRemove != null && isFirstCalculationGate && !disabled) {
        hiddenActions.push({
            label: translate("Delete"),
            hint: translate("Delete"),
            icon: DeleteIcon,
            onClick: onRemove,
            disabled: false,
        });
    }

    if (visibleActions.length === 0 && hiddenActions.length === 0) {
        return null;
    }

    return (
        <ActionsContainer className={className}>
            <FadeGradient />
            {visibleActions.map(({ onClick, hint, icon: Icon, disabled: disabledAction, label, testId }) => (
                <Tooltip key={label} title={hint ?? ""}>
                    <span>
                        {/* Wrap in span for tooltips on disabled buttons */}
                        <IconButton size="small" onClick={onClick} disabled={disabledAction ?? disabled} data-testid={testId}>
                            <Icon />
                        </IconButton>
                    </span>
                </Tooltip>
            ))}
            {hiddenActions.length > 0 ? (
                <MoreActionsMenu
                    buttonProps={{ size: "small" }}
                    actions={hiddenActions.map(({ onClick, label, icon: Icon, disabled: disabledAction }) => (
                        <MenuItem key={label} onClick={onClick} disabled={disabledAction ?? disabled}>
                            <ListItemIcon>
                                <Icon />
                            </ListItemIcon>
                            <ListItemText primary={label} />
                        </MenuItem>
                    ))}
                />
            ) : null}
        </ActionsContainer>
    );
};

export default EffectCategoryTableActions;
