import { EffectType, GenerationDto } from "api-shared";
import { useCallback, useState } from "react";
import {
    useAddEffectCategory,
    useCopyValuesFromPreviousGeneration,
    useRemoveEffectCategory,
    useUpdateEffectCategory,
} from "../../../../domain/effect-category";
import { IUpdateGenerationInput } from "../../../../domain/generation";

export interface IUseCalculationProps {
    updateGeneration: (input: IUpdateGenerationInput) => void;

    measureId: number;
    gateTaskId?: number;
    allGenerations: GenerationDto[];
    onAddEffectCategorySuccess?: (categoryId: number) => void;
}

export const useCalculation = ({
    measureId,
    gateTaskId,
    allGenerations,
    updateGeneration,
    onAddEffectCategorySuccess,
}: IUseCalculationProps) => {
    const removeEffectCategoryFromMeasure = useRemoveEffectCategory().mutate;
    const copyValuesFromPreviousGeneration = useCopyValuesFromPreviousGeneration().mutate;
    const updateEffectCategory = useUpdateEffectCategory().mutate;
    const addEffectCategoryToMeasure = useAddEffectCategory().mutate;

    const [categoryToEdit, setCategoryToEdit] = useState<number | null>(null);
    const [categoryToDelete, setCategoryToDelete] = useState<number | null>(null);
    const [defaultEffectType, setDefaultEffectType] = useState<EffectType>(EffectType.Savings);

    const generations = allGenerations.filter((generation) => generation.gateTaskId === gateTaskId);

    const addCategory = (effectType: EffectType) => {
        setDefaultEffectType(effectType);
        setCategoryToEdit(-1);
    };

    const removeEffectCategory = () => {
        if (categoryToDelete != null) {
            removeEffectCategoryFromMeasure({ measureId, id: categoryToDelete });
            setCategoryToDelete(null);
        }
    };

    const saveCategory = ({ id, effectType, calculationValues, attributes, currencyId }: Record<string, any>) => {
        if (id === undefined) {
            addEffectCategoryToMeasure(
                { measureId, categoryFields: attributes, effectType, calculationFields: calculationValues, currencyId },
                { onSuccess: (response) => onAddEffectCategorySuccess?.(response.id) },
            );
            return;
        }

        function updateCalculationValues() {
            const generation = generations.find((g) => g.gateTaskId === gateTaskId && g.effectCategoryId === id);
            if (generation !== undefined && Object.keys(calculationValues).length > 0) {
                updateGeneration({ measureId: measureId, generationId: generation.id, calculationFields: { ...calculationValues } });
            }
        }

        // An update to the EffectCategory's currency will also recalculate all effects on all generations
        // Execute generation update after category update to avoid the race between the effect updates of both requests
        if (Object.keys(attributes).length > 0 || currencyId != null) {
            updateEffectCategory(
                { effectCategoryId: id, measureId, categoryFields: attributes, currencyId },
                { onSuccess: updateCalculationValues },
            );
        } else {
            updateCalculationValues();
        }
    };

    const copyFields = useCallback(
        (effectCategoryId?: number, overrideGateTaskId?: number) => {
            const sourceGateTaskId = overrideGateTaskId ?? gateTaskId;
            if (sourceGateTaskId !== undefined) {
                copyValuesFromPreviousGeneration({ measureId, gateTaskId: sourceGateTaskId, id: effectCategoryId });
            }
        },
        [gateTaskId, measureId, copyValuesFromPreviousGeneration],
    );

    return {
        categoryToEdit,
        setCategoryToEdit,
        categoryToDelete,
        setCategoryToDelete,
        defaultEffectType,
        addCategory,
        removeEffectCategory,
        copyFields,
        saveCategory,
        generations,
    };
};
