import { Alert, Divider } from "@mui/material";
import { ClientDto, EffectType } from "api-shared";
import { TFunction } from "i18next";
import FieldList from "../../../components/FieldList";
import Form from "../../../components/Form";
import { useCurrencies } from "../../../domain/currencies";
import { Language, translationKeys } from "../../../translations/main-translations";
import CalculationForm from "./effect-category/CalculationForm";
import CurrencyForm from "./effect-category/CurrencyForm";

interface IEffectCategoryFormProps {
    translate: TFunction;
    categoryFields: any[];
    updateFieldHandler: (changes: Record<string, unknown>) => void;
    processName: string;
    measureId: number;
    client: ClientDto;
    onSubmit: () => void;
    calculationFields: any[];
    lang: Language;
    disabled?: boolean;
    effectType: EffectType;
    showGenerationLinearAlert: boolean;
    showCategoryFields: boolean;
    showCalculationFields: boolean;
    updateHasInvalidCalculation: (newValidation: boolean) => void;
    currencyId: number;
    currencyUpdated: (newValue: number) => void;
    currencyIdsInUse: number[];
}

const EffectCategoryForm = ({
    translate,
    updateFieldHandler,
    processName,
    client,
    categoryFields,
    calculationFields,
    onSubmit,
    lang,
    disabled = false,
    effectType,
    showGenerationLinearAlert,
    showCategoryFields: showCategoryFieldsProps,
    showCalculationFields,
    updateHasInvalidCalculation,
    measureId,
    currencyId,
    currencyUpdated,
    currencyIdsInUse,
}: IEffectCategoryFormProps) => {
    const showCategoryFields = categoryFields.length > 0 && showCategoryFieldsProps;
    const currencies = useCurrencies();

    return (
        <Form onSubmit={onSubmit}>
            {showCategoryFields && (
                <>
                    <FieldList
                        client={client}
                        translate={translate}
                        updateFieldHandler={updateFieldHandler}
                        fields={categoryFields}
                        preSelectOnlyValue={true}
                        processName={processName}
                        lang={lang}
                        disabled={disabled}
                        isClearable={false}
                        menuPortalTarget={document.body}
                        measureId={measureId}
                    />

                    {currencies.length > 1 && (
                        <CurrencyForm
                            currencyId={currencyId}
                            currencyUpdated={currencyUpdated}
                            currencyIdsInUse={currencyIdsInUse}
                            disabled={disabled}
                        />
                    )}
                </>
            )}
            {showCategoryFields && showCalculationFields && <Divider sx={{ my: 2, mx: -3 }} />}
            {showGenerationLinearAlert && showCalculationFields && (
                <Alert severity="warning" sx={{ mt: 3, mb: 2 }}>
                    {translate(translationKeys.VDLANG_GENERATION_LINEAR_TABLE_HINT)}
                </Alert>
            )}
            {showCalculationFields && (
                <CalculationForm
                    client={client}
                    translate={translate}
                    updateFieldHandler={updateFieldHandler}
                    calculationFields={calculationFields}
                    processName={processName}
                    lang={lang}
                    disabled={disabled}
                    effectType={effectType}
                    updateHasInvalidCalculation={updateHasInvalidCalculation}
                    measureId={measureId}
                />
            )}
        </Form>
    );
};

export default EffectCategoryForm;
